// features/credits/creditsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import env from "react-dotenv";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { createSelector } from 'reselect';


interface CreditsState {
    credits: number | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: CreditsState = {
    credits: null,
    status: 'idle',
    error: null,
};

export const fetchCredits = createAsyncThunk<number, void, { state: RootState }>(
    'credits/fetchCredits',
    async (_, { getState }) => {

        try {
            const state = getState();
            const fetchauth = await getCurrentUser();
            const userId = fetchauth.userId;
            const fetchedTokens = await fetchAuthSession({forceRefresh: true});
            const cog = fetchedTokens.tokens;
            const tokens = cog?.idToken?.toString();
            const response = await axios.post(`${env.API_URL}/get-credits/`, {
                userId: userId,
            }, {
                headers: {
                    'authorization': tokens,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });


            return response.data.credits;

        }
        catch (e:any){
            console.log(e.response.status);
            if (e.response.status === 403){
                signOut()
            }
        }
    }
);

const creditsSlice = createSlice({
    name: 'credits',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCredits.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCredits.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = 'succeeded';
                state.credits = action.payload;
            })
            .addCase(fetchCredits.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    },
});

export default creditsSlice.reducer;

// Memoized selectors using reselect
const selectCreditsState = (state: RootState) => state.credits;

export const selectCredits = createSelector(
    [selectCreditsState],
    (creditsState) => creditsState.credits
);

export const selectCreditsStatus = createSelector(
    [selectCreditsState],
    (creditsState) => creditsState.status
);

export const selectCreditsError = createSelector(
    [selectCreditsState],
    (creditsState) => creditsState.error
);
