import '../styles/common.scss';
import '../styles/components/custom_buttons.scss'
import '../styles/uploadpage.scss';
// import env from "react-dotenv";
import {Link, useNavigate} from "react-router-dom";
import React, {ChangeEvent, useRef, useState} from 'react';
import {Divider, Flex, Text} from '@aws-amplify/ui-react';
import InputLabel from '@mui/material/InputLabel';
import {remove, uploadData} from 'aws-amplify/storage';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import axios from "axios";
import env from "react-dotenv";
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import HomeIcon from '@mui/icons-material/Home';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Buffer} from 'buffer';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CreditsComponent from "../ components/creditscomp";
import CheckIcon from '@mui/icons-material/Check';
import useCredits from "../ components/getter";
import {readString} from 'react-papaparse';
import {getCurrentUser, signOut} from "aws-amplify/auth";
import Papa from 'papaparse';
import CustomProgressCircle from '../ components/custom_progress_circle'
import Snackbar from "@mui/material/Snackbar";

interface TopLines {
	columnNames: string[];
	dataRows: string[];
}

interface CsvData {
	[key: string]: string;
}

// Variable declarations
let timestamp = "";
let hashFilename = "";
let dateConfirmed = false;

let custom_num = 0;
let max_index = 2;
let titles = [
	"Ticket Number*",
	"Created Date*",
	"Description*",
];
let sectionTitles4backend = [
	"Ticket_Number",
	"Created_Date",
	"Description",
];

let contents = '';
let unParsedContents = '';

const steps = ['', '', ''];

interface UploadPageProps {
	userId: string;
	tokens: string;
	identityId: string;
}

function selectDateExample(sv:string){
	return sv.split("@")[1] + " (e.g. " + moment().format(sv.split("@")[0].trimEnd()) + ")";
}
function isInValidDateTime(dateTime: string){
	return dateTime === '' ||
		dateTime.length < 8 ||
		!isNaN(Number(dateTime)) ||
		(!moment(dateTime, "YYYY/MM/DD", false).isValid() &&
			!moment(dateTime, "DD/MM/YYYY", false).isValid() &&
			!moment(dateTime, "MM/DD/YYYY", false).isValid());
}

function listSelectedColumns(selected: any, titles: any) {
	const renderList = () => {
		const listItems = [];
		for (let i = 0; i < selected.length; i++) {
			listItems.push(<li key={i}>{selected[i] + " -> " + titles[i]}</li>);
		}
		return listItems;
	};
	return <ul>{renderList()}</ul>;
}

const UploadPage: React.FC<UploadPageProps> = ({tokens, identityId}) => {

	function IsUniqueStrictDateTimeMatch(dateTime: string) {
		let date_filter_array : string[] = [];

		const strictDateTimeFormats = ['YYYY/MM/DD HH:mm:ss',	'YYYY-MM-DD HH:mm:ss',	'YYYY.MM.DD HH:mm:ss',
			'YYYY/MM/DD HH:mm',	'YYYY-MM-DD HH:mm',	'YYYY.MM.DD HH:mm',
			'YYYY/MM/DD',	'YYYY-MM-DD',	'YYYY.MM.DD',
			'YY/MM/DD HH:mm:ss',	'YY-MM-DD HH:mm:ss',	'YY.MM.DD HH:mm:ss',
			'YY/MM/DD HH:mm',	'YY-MM-DD HH:mm',	'YY.MM.DD HH:mm',
			'YY/MM/DD',	'YY-MM-DD',	'YY.MM.DD',
			'DD/MM/YYYY HH:mm:ss',	'DD-MM-YYYY HH:mm:ss',	'DD.MM.YYYY HH:mm:ss',
			'DD/MM/YYYY HH:mm',	'DD-MM-YYYY HH:mm',	'DD.MM.YYYY HH:mm',
			'DD/MM/YYYY',	'DD-MM-YYYY',	'DD.MM.YYYY',
			'DD/MM/YY HH:mm:ss',	'DD-MM-YY HH:mm:ss',	'DD.MM.YY HH:mm:ss',
			'DD/MM/YY HH:mm',	'DD-MM-YY HH:mm',	'DD.MM.YY HH:mm',
			'DD/MM/YY',	'DD-MM-YY',	'DD.MM.YY',
			'MM/DD/YYYY HH:mm:ss',	'MM-DD-YYYY HH:mm:ss',	'MM.DD.YYYY HH:mm:ss',
			'MM/DD/YYYY HH:mm',	'MM-DD-YYYY HH:mm',	'MM.DD.YYYY HH:mm',
			'MM/DD/YYYY',	'MM-DD-YYYY',	'MM.DD.YYYY',
			'MM/DD/YY HH:mm:ss',	'MM-DD-YY HH:mm:ss',	'MM.DD.YY HH:mm:ss',
			'MM/DD/YY HH:mm',	'MM-DD-YY HH:mm',	'MM.DD.YY HH:mm',
			'MM/DD/YY',	'MM-DD-YY',	'MM.DD.YY',
			'YYYY/M/DD HH:mm:ss',	'YYYY-M-DD HH:mm:ss',	'YYYY.M.DD HH:mm:ss',
			'YYYY/M/DD HH:mm',	'YYYY-M-DD HH:mm',	'YYYY.M.DD HH:mm',
			'YYYY/M/DD',	'YYYY-M-DD',	'YYYY.M.DD',
			'YY/M/DD HH:mm:ss',	'YY-M-DD HH:mm:ss',	'YY.M.DD HH:mm:ss',
			'YY/M/DD HH:mm',	'YY-M-DD HH:mm',	'YY.M.DD HH:mm',
			'YY/M/DD',	'YY-M-DD',	'YY.M.DD',
			'DD/M/YYYY HH:mm:ss',	'DD-M-YYYY HH:mm:ss',	'DD.M.YYYY HH:mm:ss',
			'DD/M/YYYY HH:mm',	'DD-M-YYYY HH:mm',	'DD.M.YYYY HH:mm',
			'DD/M/YYYY',	'DD-M-YYYY',	'DD.M.YYYY',
			'DD/M/YY HH:mm:ss',	'DD-M-YY HH:mm:ss',	'DD.M.YY HH:mm:ss',
			'DD/M/YY HH:mm',	'DD-M-YY HH:mm',	'DD.M.YY HH:mm',
			'DD/M/YY',	'DD-M-YY',	'DD.M.YY',
			'M/DD/YYYY HH:mm:ss',	'M-DD-YYYY HH:mm:ss',	'M.DD.YYYY HH:mm:ss',
			'M/DD/YYYY HH:mm',	'M-DD-YYYY HH:mm',	'M.DD.YYYY HH:mm',
			'M/DD/YYYY',	'M-DD-YYYY',	'M.DD.YYYY',
			'M/DD/YY HH:mm:ss',	'M-DD-YY HH:mm:ss',	'M.DD.YY HH:mm:ss',
			'M/DD/YY HH:mm',	'M-DD-YY HH:mm',	'M.DD.YY HH:mm',
			'M/DD/YY',	'M-DD-YY',	'M.DD.YY',
			'YYYY/MM/D HH:mm:ss',	'YYYY-MM-D HH:mm:ss',	'YYYY.MM.D HH:mm:ss',
			'YYYY/MM/D HH:mm',	'YYYY-MM-D HH:mm',	'YYYY.MM.D HH:mm',
			'YYYY/MM/D',	'YYYY-MM-D',	'YYYY.MM.D',
			'YY/MM/D HH:mm:ss',	'YY-MM-D HH:mm:ss',	'YY.MM.D HH:mm:ss',
			'YY/MM/D HH:mm',	'YY-MM-D HH:mm',	'YY.MM.D HH:mm',
			'YY/MM/D',	'YY-MM-D',	'YY.MM.D',
			'D/MM/YYYY HH:mm:ss',	'D-MM-YYYY HH:mm:ss',	'D.MM.YYYY HH:mm:ss',
			'D/MM/YYYY HH:mm',	'D-MM-YYYY HH:mm',	'D.MM.YYYY HH:mm',
			'D/MM/YYYY',	'D-MM-YYYY',	'D.MM.YYYY',
			'D/MM/YY HH:mm:ss',	'D-MM-YY HH:mm:ss',	'D.MM.YY HH:mm:ss',
			'D/MM/YY HH:mm',	'D-MM-YY HH:mm',	'D.MM.YY HH:mm',
			'D/MM/YY',	'D-MM-YY',	'D.MM.YY',
			'MM/D/YYYY HH:mm:ss',	'MM-D-YYYY HH:mm:ss',	'MM.D.YYYY HH:mm:ss',
			'MM/D/YYYY HH:mm',	'MM-D-YYYY HH:mm',	'MM.D.YYYY HH:mm',
			'MM/D/YYYY',	'MM-D-YYYY',	'MM.D.YYYY',
			'MM/D/YY HH:mm:ss',	'MM-D-YY HH:mm:ss',	'MM.D.YY HH:mm:ss',
			'MM/D/YY HH:mm',	'MM-D-YY HH:mm',	'MM.D.YY HH:mm',
			'MM/D/YY',	'MM-D-YY',	'MM.D.YY',
			// Add the rest of the datetime formats here
		];

		for (const format of strictDateTimeFormats) {
			if (moment(dateTime, format, true).isValid()) {
				date_filter_array.push(format);
			}
		}

		if (date_filter_array.length > 0) {
			let uniqueArray: string[] = Array.from(new Set(date_filter_array));
			if (uniqueArray.length === 1) {
				dateConfirmed = true;
				updateDateFormat(uniqueArray[0]);
				return true;
			} else {
				let uniqueFirstCharArray: string[] = Array.from(new Set(uniqueArray.map(str => str.charAt(0))));

				if(uniqueFirstCharArray.length === 1){
					dateConfirmed = true;
					updateDateFormat(uniqueArray[0]);
					// console.log("Setting dateconfirmed to true",dateFormat);
					return true;
				}
			}
		}
		return false;
	}

	const navigate = useNavigate()


	// stepper//
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState<{
		[k: number]: boolean;
	}>({});

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
			      // find the first step that has been completed
				steps.findIndex((_step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	// If Back button clicked
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		max_index = 2
		custom_num = 0;
		titles = titles.slice(0 ,3);
		setSelectedColumns(titles.map(() => ''));
		dateConfirmed = false;
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};



	const credits = useCredits() ?? -1;
	const [creditWarning] = useState(50);
	const [csvError, setCsvError] = useState(false);
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [isFileLoading, setIsFileLoading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [topLines, setTopLines] = useState<TopLines>({columnNames: [], dataRows: []});
	const [isDropAreaActive, setDropAreaActive] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [filename, setFilename] = useState<string>('');
	const [file, setFile] = useState<File | null>(null);
	const [goClicked, setGoClicked] = useState(false);
	const custom_titles = 'TEST_custom';
	const [concatenatedList, setConcatenatedList] = useState<string[]>([]);
	const [selectedColumns, setSelectedColumns] = useState<string[]>(titles.map(() => ''));
	const [selectedState, setSelectedState] = useState<boolean[]>(Array.from({length: 25}, () => false));
	const areFirstThreeOnes: 0 | boolean = selectedState[0] && selectedState[1]  && selectedState[2]  && dateConfirmed;
	const [start_process, setStart_process] = useState(false);
	const [upload_complete, setUpload_complete] = useState(false);
	const [leng_file, setLeng_file] = useState<number>(0);
    const [loading, setLoading] = useState(false);
	const [uploadError, setUploadError] = useState(false);
	const [uploadErrorMessage, setUploadErrorMessage] = useState("Error");
	const [displayDateFormat, setDisplayDateFormat] = useState<string>('');
	const dateFormat = useRef('');
	const [progress, setProgress] = useState(0);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);

	const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	// Programmatic update
	const updateDateFormat = (value: string) => {
		dateFormat.current = value;
		// setDateFormat(value);
	};

	const updateConcatenatedList = (newConcatenatedList: string[]) => {
		setConcatenatedList(newConcatenatedList);
	};
	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		handleFiles(files);
	};
	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setCsvError(false);
		setDropAreaActive(true);
		handleUnselectFile()
		// reset input
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};
	const handleDragLeave = () => {
		setDropAreaActive(false);
	};
	const handleBrowseClick = () => {
		handleUnselectFile()
		// reset input
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		fileInputRef.current?.click();
	};
	const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		handleFiles(files);
	};

	const getCurrentTimestamp = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
		const milliseconds = String(now.getMilliseconds()).padStart(2, '0');

		return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}`;

	}




	const generateHashFilename = () => {
		const [name, extension] = filename.split('.');
		const newFilename = `${name}[${timestamp}].${extension}`;

		// Convert filename to bytes
		const buffer = Buffer.from(newFilename);
		const buffedString = buffer.toString('base64');
		hashFilename = buffedString.replace('/', '_').replace('+', '-');
	};


	const handleFiles = (files: FileList | null) => {
		if (uploading) {
			alert('Cancel the current upload before selecting a new file.');
			return;
		}

		const selectedFile = files?.[0];

		if (selectedFile) {
			setFilename(selectedFile.name);
			setFile(selectedFile);
			setIsFileSelected(true); // Set the flag to indicate that a file is selected
			setIsFileLoading(true);

			const reader = new FileReader();
			reader.onload = function (e) {
				contents = e.target?.result as string;

                // Use readString from usePapaParse hook to parse the CSV contents
				readString<CsvData>(contents, {
					header: true,
					quoteChar: '"',
					skipEmptyLines: "greedy",

					complete: (results) => {
						const { data, errors, meta } = results;
						if (results.data && results.data.length === 0) {
							console.log("No data in file", errors);
							setCsvError (true );
							setIsFileLoading(false);
						}
						else if (results.errors && results.errors.length > 0) {
							console.log("File format error", errors);
							setCsvError (true );
							setIsFileLoading(false);

						}
						else if (results.meta.fields && results.meta.fields.length <3) {
							console.log("Less than 3 columns", meta);
							setCsvError (true );
							setIsFileLoading(false);

						}
						else if (results.meta.delimiter && results.meta.delimiter !==',') {
							console.log("Unsupported delimiter", results.meta.delimiter);
							setCsvError (true );
							setIsFileLoading(false);

						}
						else{
							unParsedContents = Papa.unparse(data);

							//let the column names and the first row of data
							const columnNames:string[] = results.meta.fields && results.meta.fields.length > 0 ?
								results.meta.fields : [];
							const firstDataRow = data && data.length > 0 ? Object.values(data[0]) as string[] : [];

							// Update the state with the parsed column names and data rows
							const topLines: TopLines = {
								columnNames,
								dataRows: firstDataRow,
							};
							setIsFileLoading(false);
							updateConcatenatedList(firstDataRow);
							setTopLines(topLines);
							setLeng_file(data.length); // Set the length of the file
							setDropAreaActive(false);
						}
					},
				});
			};



			reader.readAsText(selectedFile);
		}
	};

	const handleUnselectFile = () => {
		setUploading(false)
		setFilename('');
		setFile(null);
		setIsFileSelected(false);
		dateConfirmed = false;
		setCsvError(false)
		setUploadError(false)
		setIsFileLoading(false);
		// Add any additional cleanup logic if needed
	};

	const handleDropdownChange = (index: number, value: string) => {
		const tempSelectedColumns = [...selectedColumns];

		tempSelectedColumns[index] = value;
		// console.log(tempSelectedColumns);

		setSelectedColumns(tempSelectedColumns);
		if (index === 1) dateConfirmed = false;
		setSelectedState(prevNumbers => {
			const newNumbers = [...prevNumbers];
			newNumbers[index] = true;
			return newNumbers;
		});

	};

	const addDIMTitle = () => {
		custom_num++;
		titles.push("Optional Field #"+custom_num)
		sectionTitles4backend.push(`${custom_titles}${custom_num}`);
		max_index = titles.length - 1;
		const tempSelectedColumns = [...selectedColumns];
		tempSelectedColumns.push();
		tempSelectedColumns[max_index] = '';
		setSelectedColumns(tempSelectedColumns);
	};

	const removeDIMTitle = () => {
		const tempSelectedColumns = [...selectedColumns];
		tempSelectedColumns.pop();
		setSelectedColumns(tempSelectedColumns);
		titles.pop();
		sectionTitles4backend.pop();
		custom_num--;
		max_index = titles.length - 1;
	};

	const failProcess = (status_error:any, error_mess:any) => {
		handleClose()
		delete_file()
		setActiveStep(0)
		const timestamp = getCurrentTimestamp();

		return(
			window.alert(`${status_error},\n${error_mess},\n[${timestamp}]`)
		)

	}

	const mapped = (columnMapping: any) => {


		const restValues: Record<string, string> = {};
		const firstThree: Record<string, string> = {}; // Define the type of firstThree

		// Loop through the columnMapping object
		for (const [key, value] of Object.entries(columnMapping)) {
			// Check if key is one of the first three
			if (["Ticket_Number", "Description", "Created_Date"].includes(value as string)) {
				// Add key-value pair to firstThree object
				firstThree[key as string] = value as string; // Type assertion
			} else {
				// Add value to restValues array
				restValues[key as string] = key as string; // Type assertion
			}
		}


		// Combine the firstThree object and the restObject using the spread operator
		return {...firstThree, ...restValues}
	}


	const handleChangeDate = (event: SelectChangeEvent) => {
		// setDateFormat (event.target.value as string);
		dateFormat.current = event.target.value;
		setDisplayDateFormat(dateFormat.current);
	};

	const handleGo = async () => {
		if (!filename || !file) {
			console.error('Filename or file is not defined.');
			return;
		}

		// Read the file content to count the lines
		const reader = new FileReader();
		reader.onload = async function (e) {
			contents = e.target?.result as string;
			// Specify the maximum allowed lines (adjust as needed)
			const maxAllowedCredits = 100000
			const maxAllowedLines = credits >= 0 ? credits : maxAllowedCredits;
			const minAllowedLines = 1;

			if (leng_file > maxAllowedLines) {
				// Alert user that the file has too many lines
				alert(`File has more than ${maxAllowedLines} rows. Please upload a file with fewer lines.`);
				return;
			}

			if (leng_file < minAllowedLines) {
				// Alert user that the file has too many lines
				alert(`File has less than ${minAllowedLines} rows. Please upload a file with more lines.`);
				return;
			}

			setGoClicked(true);
			handleComplete();
		};
		reader.readAsText(file);
	};


	const saveVariables = async () => {
		setStart_process(true);
		setUploading(true);
		timestamp = getCurrentTimestamp();
		generateHashFilename()

		const [name, extension] = filename.split('.');
		const newfilename = `${name}[${timestamp}].${extension}`;
			// Convert filename to bytes
		const buffer = Buffer.from(newfilename);
		const buffedString = buffer.toString('base64');
		const encodedString = buffedString.replace('/','_').replace('+','-')

		try {
			const result = await uploadData({
				key: `${encodedString}/upload/${filename}`,
				data: new Blob([unParsedContents], {type: 'text/csv'}),
				options: {
					accessLevel: 'private',
					onProgress: ({transferredBytes, totalBytes}) => {
						if (totalBytes) {
							setProgress(Math.round((transferredBytes / totalBytes) * 100));
						}
					},
				}
			}).result;

			const fetchauth = await getCurrentUser();
			const userId = fetchauth.userId;
			const apiUrl = `${env.API_URL}/process-csv/`;
			const variablesData = {
				user_id: userId,
				input_s3_key: `private/${identityId}/${result.key}`,
				column_mapping: {} as Record<string, string>,
				date_format: dateFormat.current[0].toLowerCase(),
			};


			sectionTitles4backend.forEach((backendTitle, index) => {
				const chosenColumn = selectedColumns[index];
				if (chosenColumn !== undefined && chosenColumn.length > 0) {
					variablesData.column_mapping[chosenColumn] = backendTitle;
				}
			});

			variablesData.column_mapping = mapped(variablesData.column_mapping)

			const jsonString = JSON.stringify(variablesData, null, 2);
			// console.log(jsonString);

			// Make the POST request using Axios
			// try {
				setLoading(true);
				const response = await axios.post(apiUrl, jsonString, {
					headers: {
						'authorization': tokens,
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				});

				if (response.status === 200) {
					// console.log("BE response:", response);
					handleComplete();
					setStart_process(false);
					setUpload_complete(true);
					setUploadError(false);
					setTimeout(() => {
						navigate('/download', );
						window.location.reload(); // Force a full page reload
					}, 2000);
				} else {
					setLoading(false);
					setStart_process(false);
					setUploadError(true);
					setUpload_complete(false);
					setUploadErrorMessage("Processing issue. Please try again later");
					failProcess("Processing issue", "");
				}

			} catch (error: any) {
				// console.error('Error:', error);
				setLoading(false);
				setStart_process(false);
				setUploadError(true);
				setUpload_complete(false);

				if (error.response) {
					if (error.response.status === 403 || error.response.status === 401) {
						await signOut();
					} else if (error.response.status >= 500) {
						setUploadErrorMessage("Server issue. Please try again later");
						// failProcess("server issue","");
					} else if (error.response.status === 422) {
						setUploadErrorMessage("File issue. Try a different file.");
						failProcess("File issue", "");
					}
				} else {
					console.error('Network or other error:', error.message);
					setUploadErrorMessage("Network or other error. Please try again later");
					// failProcess("Network issue","");
				}
			}



	};
	// Save to persistent storage


	function date_confirmed() {
		return dateConfirmed;
	}

	const delete_file = () => {
		try {
			remove({key: `${hashFilename}/upload/${filename}`, options: {accessLevel: 'private'}});
			setGoClicked(false);
			handleBack();
			handleUnselectFile()
			const newNumbers = Array.from({ length: 25 }, () => false);
			setSelectedState(newNumbers);

		} catch (error) {
			console.log('Error ', error);
		}
	};




	const [open, setOpen] = React.useState(false);

	const handleUploadClick = () => {
		setOpen(true);
		const tempColumn = selectedColumns.filter(item => item.trim() !== '');
		setSelectedColumns(tempColumn);
	};

	const handleClose = () => {

		// Add code to handle closing/cancel if uploading, maybe use a snackbar
		// if (uploading) return;
		setOpen(false);
		setUploadError(false);
		if(start_process) {
			setSnackbarOpen(true);
		}
	};

	const [openDate, setOpenDate] = React.useState(false);

	const [dict_date_menu] = useState<{ [key: string]: string }>({
		'Day first' : "D MMM YYYY@Day first",
		'Month first' : "MMM D YYYY@Month first",
		'Year first' : "YYYY MMM D@Year first",
	});
	const handleOpenDate = () => {
		setOpenDate(true);
	}
	const handleCloseDate = () => {
		setOpenDate(false)
		dateConfirmed = false;
		}
	;
	const handleCloseDateconfirm = () => {
		setOpenDate(false)
		dateConfirmed = true;
	}

	return (
		<>
		<div className={'pager'}>

			{activeStep === 0 && (
				<div className={"top_nav"}>
					<span className="page_path"> <a href = "/"><HomeIcon className={'home-icon'} />&nbsp;Home</a> / Analyze / Select File</span>
					<CreditsComponent creditWarning={50} />
				</div>
			)}
			{
				activeStep === 1 && (
					<div className={"top_nav"}>
									<span className="page_path"> <a href = "/"><HomeIcon className={'home-icon'} />&nbsp;Home</a> / Analyze / Map Columns</span>
						<CreditsComponent creditWarning={50} />

								</div>

							)
						}
			{
				goClicked ? (
					<div className={'main'}>
						<h1>Map file columns</h1>
						<div className={'upload-table-div-mobile hidden-desktop'}>
							{titles.map((title, index) => (
								<div className={"upload-section-mobile"} key={index}>
								<div className={'upload-section_titles-mobile'}>
									<span className={'upload-column-name-mobile'}>{title}</span>
									{
										selectedState[index] // Selected
											? index === 1 // Date column
												? isInValidDateTime(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]) //Invalid Date Format ?
													? <span className={'upload-column-alert-mobile alert-danger'}>Invalid date</span> // Red flag
													: (date_confirmed() || IsUniqueStrictDateTimeMatch(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])])) // Matched Strict or Date Confirmed ?
														? <span className={'upload-column-alert-mobile alert-success'}>Selected</span> // Green
														: <span className={'upload-column-alert-mobile '}><React.Fragment>
																	<button
																		className={'confirm-warning-mobile'}
																		onClick={handleOpenDate}>
																		Confirm
																	</button>
																	<Dialog
																		fullWidth
																		open={openDate}
																		onClose={handleCloseDate}
																		aria-labelledby="alert-dialog-title"
																		aria-describedby="alert-dialog-description">
																		<DialogTitle
																			id="alert-dialog-title">
																			{"Date Format"}
																		</DialogTitle>
																		<DialogContent>
																			<DialogContentText
																				id="alert-dialog-description">
																				<p>Select the format of this date field:
																					<b>{concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]}</b>
																				</p>
																				<FormControl fullWidth>
																					<InputLabel
																						id="demo-simple-select-label">Select...</InputLabel>
																					<Select
																						labelId="demo-simple-select-label"
																						id="demo-simple-select"
																						value={displayDateFormat}
																						label="Standard Date formats"
																						onChange={handleChangeDate}>
																						{Object.entries(dict_date_menu).map(([value, showValue]) =>
																							<MenuItem key={value}
																							          value={value}>
																								{selectDateExample(showValue)}&nbsp;Hello
																							</MenuItem>)}
																					</Select>
																				</FormControl>
																			</DialogContentText>
																		</DialogContent>
																		<DialogActions>
																			<button
																				className={'custom_button-secondary'}
																				onClick={handleCloseDate}>Cancel
																			</button>
																			<button
																				className={'custom_button'}
																				onClick={handleCloseDateconfirm}
																				autoFocus>
																				Confirm
																			</button>
																		</DialogActions>
																	</Dialog>
																</React.Fragment></span>
												: <span className={'upload-column-alert-mobile alert-success'}>Selected</span>
											: index < 3 // One of the required fields ?
												? <span className={'upload-column-alert-mobile alert-danger'}>Required</span> // Flag as required
												: <span className={'upload-column-alert-mobile alert-neutral'}>Optional</span> // Flag as optional
									}
									<span className={'upload-flags-mobile'}>
										<p className={"upload-status-flag-mobile"}>
											{
												selectedState[index]  // Selected
													? index === 1 // Date column
														? isInValidDateTime(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])])  //Invalid Date Format ?
																? <div><div><PriorityHighIcon className={"icon-red"}/></div></div>
																: (date_confirmed() || IsUniqueStrictDateTimeMatch(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]) )  // Matched Strict or Date Confirmed ?
																	? <div><div><CheckOutlinedIcon className={"icon-selected"}/></div></div>
																	: <div><div><PriorityHighIcon className={"icon-warning"}/></div></div>
														: <div><div><CheckOutlinedIcon className={"icon-selected"}/></div></div>
													: index < 3
														? <div><CircleIcon className={"icon-faded-red"}/></div>
														: <div><CircleIcon className={"icon-faded"}/></div>
											}

										</p>
									</span>
								</div>
								<div className={'upload-section-values-mobile'}>

									<div className={'upload-selector-mobile'}>
										<FormControl fullWidth>
											{/*<InputLabel>Select column</InputLabel>*/}
											<Select
												className='upload-drop_down-mobile'
												value={selectedColumns[index] || ''}
												onChange={(e) => handleDropdownChange(index, e.target.value)}>
												{topLines.columnNames.map((columnName, columnIndex) => (
													<MenuItem selected
														// className="MenuItem"
														      key={columnIndex}
														      value={columnName}
														      disabled={selectedColumns.includes(columnName)}>
														{columnName}
													</MenuItem>

												))}
											</Select>
										</FormControl>
									</div>

									{selectedState[index]
										? (index === 1
											? (<div className={'upload-values_preview-mobile'}>
													<p className={'ellipsis-p'}>{concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]}</p>
												</div>)
											: (<div className={'upload-values_preview-mobile'}>
												<p className={'ellipsis-p'}>{concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]}</p>
											</div>))
										: (<div className={'upload-values_preview-mobile'}>
											<p><i>Please select a column.</i></p>
										</div>)
									}
								</div>

							</div>


						))}

						{titles.map((_title, index) => (
							<div className={'upload-controls'} key={index}>
								{max_index === index && (
									<div className={'upload-controls-inner'}>
										<p>
											<button className={'remove_row'} onClick={removeDIMTitle}
											        hidden={titles.length < 4}
											        disabled={titles.length < 4}>
												<RemoveCircleOutlinedIcon/>
											</button>
											<button className={"add_row"} onClick={addDIMTitle}
											        hidden={titles.length > 9 || selectedColumns[max_index] === '' || selectedColumns[max_index] === undefined}
											        disabled={titles.length > 9 || selectedColumns[max_index] === '' || selectedColumns[max_index] === undefined}>
												<AddCircleOutlinedIcon/>
											</button>
										</p>
									</div>
								)}
							</div>
						))}

					</div>
					<div className={'table-div-desktop hidden-mobile'}>
						<div className={'upload-section-header-desktop'}>
							{/*<div className={'tablehead0'}><p></p></div>*/}
							<div className={"tablehead1"}><p>Voxxify Property</p></div>
							<div className={'tablehead2'}><p>Column from File</p></div>
							<div className={'tablehead3'}><p>Column Preview</p></div>
							<div className={'tablehead4'}><p>Status</p></div>
						</div>
						<div className={'upload-table-desktop'}>
							{titles.map((title, index) => (
								<div className={"upload-table-desktop-section"} key={index}>

									<div className={'section_titles'}>
										<p>{title}</p>
									</div>

									<div className={'selector'}>
										<FormControl fullWidth>
											{/*<InputLabel>Select column</InputLabel>*/}
											<Select
												// label="Select column"
												className='drop_down'
												value={selectedColumns[index] || ''}
												onChange={(e) => handleDropdownChange(index, e.target.value)}
											>
												{topLines.columnNames.map((columnName, columnIndex) => (
													<MenuItem selected
														// className="MenuItem"
														      key={columnIndex}
														      value={columnName}
														      disabled={selectedColumns.includes(columnName)}>
														{columnName}
													</MenuItem>

												))}
											</Select>
										</FormControl>
									</div>

									{selectedState[index]
										? <div className={'values_preview'}>
											<p className={'ellipsis-p'}>{concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]}</p>
										</div>
										: <div className={'values_preview'}>
											<p>Select a value</p>
										</div>
									}
									{/*</p>*/}
									{/*</div>*/}


									<div className={'flags'}>
										<p className={"status-flag"}>
											{/*{index === 1 && date_confirmed() ? (*/}
											{/*	<button onClick={handleOpenDate}>change</button>*/}
											{/*) : (*/}
											{/*	<div></div>*/}
											{/*)}*/}
											{
												selectedState[index]
													? (index === 1
														? (isInValidDateTime(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])])
																? <div>
																	<div>
																		<PriorityHighIcon className={"icon-red"}/>
																		<p className={'alert-danger'}>invalid date</p>
																	</div>
																</div>
																: (date_confirmed() || IsUniqueStrictDateTimeMatch(concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])])
																	? <div>
																		<div>
																			<CheckOutlinedIcon
																				className={"icon-selected"}/>
																			<p className={'alert-success'}>Selected</p>
																		</div>
																	</div>
																	: <div>
																		<div>
																			<PriorityHighIcon
																				className={"icon-warning"}/>
																		</div>
																		<div>
																			<div>
																				<React.Fragment>
																					<button
																						className={'confirm-warning'}
																						onClick={handleOpenDate}>
																						Confirm
																					</button>
																					<Dialog
																						fullWidth
																						open={openDate}
																						onClose={handleCloseDate}
																						aria-labelledby="alert-dialog-title"
																						aria-describedby="alert-dialog-description"
																					>
																						<DialogTitle
																							id="alert-dialog-title">
																							{"Confirm Date Format"}

																							</DialogTitle>
																							<DialogContent>
																								<DialogContentText
																									id="alert-dialog-description">
																									<p>Select the <b>date format</b> of
																										this field: <b>{concatenatedList[topLines.columnNames.indexOf(selectedColumns[index])]}</b>
																									</p>
																									<FormControl fullWidth>
																										<InputLabel
																											id="demo-simple-select-label">Select...</InputLabel>
																										<Select
																											labelId="demo-simple-select-label"
																											id="demo-simple-select"
																											value={dateFormat.current}
																											label="Standard Date formats"
																											onChange={handleChangeDate}
																										>
																											{Object.entries(dict_date_menu).map(([value, showValue]) => (
																												<MenuItem key={value} value={value}>
																													{selectDateExample(showValue)}
																												</MenuItem>
																											))}
																										</Select>
																										{/*<TextField  id="outlined-basic" label="Custom Date Format" variant="outlined" />*/}
																									</FormControl>
																								</DialogContentText>
																							</DialogContent>
																							<DialogActions>
																								<button
																									className={'custom_button-secondary'} onClick={handleCloseDate}>Cancel</button>
																								<button className={'custom_button'} onClick={handleCloseDateconfirm} autoFocus>
																									Confirm
																								</button>
																							</DialogActions>
																						</Dialog>
																					</React.Fragment>
																				</div>
																			</div>
																		</div> )
															)
														: <div>
																<div>
																	<CheckOutlinedIcon className={"icon-selected"}/>
																	<p className={'alert-success'}>Selected</p>
																</div>
															</div>)
													: (index < 3
														? <div>
																<CircleIcon className={"icon-faded-red"}/>
																<p className={'alert-danger'}>Required</p>
															</div>
														: <div>
																<CircleIcon className={"icon-faded"}/>
																<p className={'alert-neutral'}>Optional</p>
															</div>)
												}

										</p>
									</div>


								</div>

							))}
							{titles.map((_title, index) => (
								<div className={'upload-controls'}>
								{max_index === index && (
									<div className={'upload-controls-inner'}>
										<p>
											<button className={'remove_row'} onClick={removeDIMTitle}
											        hidden={titles.length < 4} disabled={titles.length < 4}>
												<RemoveCircleOutlinedIcon/>
											</button>
											<button className={"add_row"} onClick={addDIMTitle}
											        hidden={titles.length > 9 || selectedColumns[max_index] === '' || selectedColumns[max_index] === undefined}
											        disabled={titles.length > 9 || selectedColumns[max_index] === '' || selectedColumns[max_index] === undefined}>
												<AddCircleOutlinedIcon/>
											</button>
										</p>

									</div>
								)}
							</div>
								))}

						</div>

					</div>
					<div className={"bottom_nav"}>
						<div className={"button-pair"}>
								<button onClick={delete_file} className={'custom_button-secondary'}>Back</button>
							<React.Fragment>
								<button className={'custom_button'} onClick={handleUploadClick} disabled={!areFirstThreeOnes }>
									Upload
								</button>
								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogTitle id="alert-dialog-title" className={'dialogTitleDark'}>
										{"Confirmation"}
									</DialogTitle>
									<DialogContent className={'dialogContent'}>
										<DialogContentText>
											<b>Tickets</b>: {leng_file}<br/>
											<b>Credits</b>: <Chip label={leng_file} size='small'
											                      sx={{m: 0, display: 'inline-flex', position: 'relative'}}
											                      color={'success'}
											                      variant="outlined"/><br/>
											<b>Columns Selected</b>:{listSelectedColumns(selectedColumns, titles)}
											<b>Date Format</b>: {dateFormat.current}
										</DialogContentText>
									</DialogContent>
									{uploadError && (<Alert severity="error">{uploadErrorMessage}</Alert>)}
									<DialogActions>
										<button className={'top-up-button'}
										        onClick={saveVariables}
										        disabled={start_process || upload_complete}
										        autoFocus>
											{
												start_process && progress < 100 ?
													<span className={'upload-button'}><CircularProgress
														variant={'determinate'} sx={{color: 'white'}}
														value={progress}
														size="1rem" thickness={4}/></span>
													: start_process && progress === 100 ?
														<span className={'upload-button'}>
																	<CircularProgress
																		size="1rem" color="inherit"/></span>
														: upload_complete
															? <span className={'upload-button'}><CheckIcon
																fontSize={'small'}/></span>
															: uploadError
																? "RETRY"
																: "CONFIRM"
											}
										</button>
									</DialogActions>
								</Dialog>
							</React.Fragment>

						</div>
					</div>
					</div>
				) : (
					<div className={'main'}>
						<h1>Select file to analyze</h1>
					<div className={'table-div-upload'}>
						<div
							id="drop-area"
							onDrop={handleDrop}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							className={`drop-area ${isDropAreaActive ? 'active' : ''}`}
						>
							<label htmlFor="file-input">
								<Flex
									alignItems="center"
									direction="column"
									fontSize={"xl"}
								>
									<Text>Drop file here</Text>
									<Divider size="small" label="or" maxWidth="10rem" margin={'large'}/>
								</Flex>
								<button className={'bx_bttn-center'} disabled={isFileLoading} onClick={handleBrowseClick}>
									{isFileLoading ? "Reading" : "Browse"}
								</button>
								{isFileSelected && (
									<div>
										<Chip sx={{ m: 2 }} label={filename} onDelete={handleUnselectFile} />
										{!isFileLoading &&
											<div>
										<div>
											<Stack spacing={1} margin={'large'}>
												{csvError ? (<Alert severity="error">File format error. Reformat the file or choose another file.</Alert>) :
												leng_file <= credits && credits >= 0 && (<Alert severity="success">This file will
													use <b>{leng_file}</b> credit{leng_file !== 1 ? "s." : "."}
												</Alert>)}
												{leng_file < creditWarning && (
													<Alert severity="warning" >
														This file has &lt; {creditWarning} rows. It may not generate useful topics.
													</Alert>
												)}
											</Stack>
										</div>
										<div>
											<Stack spacing={1} margin={'large'}>
												{leng_file > credits && credits >= 0 && (
													<Alert severity="error" action={
														<Button component={Link} to={"/settings?credits=" + (leng_file-credits)} color="inherit" size="small" >
															Top Up
														</Button>
													}>
														To analyze this file you will need an additional <b>{leng_file-credits}</b> credit{leng_file-credits !== 1 ? "s." : "."}
													</Alert>
												)}
											</Stack>
										</div>
									</div>
										}

									</div>
								)}
								{!isFileSelected && (<Chip sx={{ m: 2 }} label={"csv"}/>)}
								<input ref={fileInputRef}
								       type="file"
								       id="file-input"
								       accept=".csv"
								       style={{display: 'none'}}
								       onChange={handleFile}/>
							</label>
						</div>
					</div>
					<div className={'bottom_nav'}>
						<div className={'button-pair'}>
							<Link to="/">
								<button className={'custom_button-secondary'} onClick={handleBack}>Back</button>
							</Link>
							<button className={'custom_button'} onClick={handleGo}
							        disabled={!isFileSelected || csvError || leng_file > credits}>
								Next
							</button>
						</div>
					</div>
				</div>
			)}
			{/*</div>*/}
		</div>
	<React.Fragment>
		<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
			<Alert
				onClose={handleSnackbarClose}
				severity="success"
				sx={{width: '100%'}}
			>
				Uploading will continue in the background.
			</Alert>
		</Snackbar>
	</React.Fragment>
	</>
	);

};


export default UploadPage;
