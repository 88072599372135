// app/store.ts
import { configureStore } from '@reduxjs/toolkit'
import creditsReducer from './slice/credits'
// Import other reducers

const store = configureStore({
    reducer: {
        credits: creditsReducer,
        // Add other reducers here
    }
})

// Define the RootState type
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store