// Filename - App.js
import React from "react";
import "../styles/common.scss";
import "../styles/homepage.scss";
import "../styles/download.scss";
import "../styles/components/custom_buttons.scss"
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CreditsComponent from "../ components/creditscomp";




function Home() {
	return (
		<div>
			<div className={"top_nav"}>
				<span className="page_path"><HomeIcon className={'home-icon'} />&nbsp;Home</span>
				<CreditsComponent creditWarning={50} />
			</div>
			<div className="main">
				<div className="page-body full-width flush docs-page">
					<div className="docs-body">

						<div className="page-body vxf-page">
							<div className="vxf-build vxf-section"><h2>Upload files for AI analysis</h2>
								<div className="vxf-build-grid"><a href="/upload">
									<div className="icon-item ">
										<div className="icon-item-icon default-gradient-bg">
											<SupportAgentOutlinedIcon/>
										</div>
										<div className="icon-item-right">
											<div className="icon-item-title body-large bold">Ticket analyzer</div>
											<div className="icon-item-desc body-small">Reveal key trends in incident data.
											</div>
										</div>
									</div>
								</a><a href="/">
									<div className="icon-item ">
										<div className="icon-item-icon default-gradient-bg">
											<EmojiEmotionsOutlinedIcon/>
										</div>
										<div className="icon-item-right">
											<div className="icon-item-title body-large bold">Survey analyzer</div>
											<div className="icon-item-tag">Coming soon</div>
											<div className="icon-item-desc body-small">Discover topics in freeform text.
											</div>
										</div>
									</div>
								</a></div>
							</div>
							<div className="vxf-build vxf-section"><h2>Manage content & credits</h2>
								<div className="vxf-build-grid">
									<a href="/download">
										<div className="icon-item ">
											<div className="icon-item-icon default-gradient-bg">
												<BarChartIcon/>
												{/*import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';*/}
											</div>
											<div className="icon-item-right">
												<div className="icon-item-title body-large bold">Results dashboard</div>
												<div className="icon-item-desc body-small">Browse and download topics & trends.
												</div>
											</div>
										</div>
									</a>
									<a href="/settings">
										<div className="icon-item ">
											<div className="icon-item-icon default-gradient-bg">
												<StorefrontOutlinedIcon/>
											</div>
											<div className="icon-item-right">
												<div className="icon-item-title body-large bold">Credit store</div>
												<div className="icon-item-desc body-small">Buy credits & redeem coupons.
												</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
						<div className="docs-footer">
							<div className="docs-feedback"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
