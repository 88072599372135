// src/components/custom_progress_circle.tsx
import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CustomProgressCircleProps extends CircularProgressProps {
  value: number;
  size?: string;
}



const CustomProgressCircle: React.FC<CustomProgressCircleProps> = (props) => {
    let sizeValue = 1; // Default size value
      if (props.size) {
        const parsedSize = parseFloat(props.size.replace('rem', ''));
        if (!isNaN(parsedSize)) {
          sizeValue = parsedSize;
        }
      }
      const fontSize = sizeValue * 5;
  return (
      <Box sx={{boxSizing: 'border-box',marginRight: '3px', left: 4, position: 'relative', height: '16px', display: 'inline-flex'}}>
        {/* Background Circular Progress */}
        <CircularProgress variant="determinate" value={100} sx={{ boxSizing: 'border-box', padding: 0,color: 'lightgray',position: 'absolute'  }}  size={props.size} thickness={props.thickness} />
        {/* Foreground Circular Progress */}
        <CircularProgress variant="determinate" {...props} sx={{left: -2, top:0, boxSizing: 'border-box',padding: 0,position: 'absolute'  }} size={props.size} thickness={props.thickness}/>
        {/* Centered Text */}
        <Box
          sx={{
            top: 1,
            left: 1,
            bottom: 0,
            right: 0,
              width: '15px',
              height: '15px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        <Typography
          variant="caption"
          component="div"
          color='info.main'
           sx={{ fontSize: `${fontSize}px` }}>
                {`${Math.round(props.value)}%`}
        </Typography>
        </Box>
      </Box>
  );
};

export default CustomProgressCircle;
