import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {downloadData, getUrl} from 'aws-amplify/storage';
import "../styles/download.scss"
import HomeIcon from '@mui/icons-material/Home';
import CreditsComponent from "../ components/creditscomp";
import {readString} from "react-papaparse";
import {
	DataGrid,
	GridToolbar,
	GridColDef,
	GridRenderCellParams,
	GridRowsProp,
	GridValidRowModel
} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import {BarChart} from "@mui/x-charts/BarChart";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import Stack from "@mui/material/Stack";
import {FormControlLabel, List, ListItem, ListItemText, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import {PieChart,pieArcLabelClasses } from "@mui/x-charts/PieChart";
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from '@mui/icons-material/PieChart';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{p: 3, width: '100%', height: 1100}}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}


// interface CsvData {
// 	[key: string]: string;
// }

// Define interfaces for the structure of your JSON data
interface Meta {
	period: string;
	min_date: string;
	max_date: string;
	max_value: number;
}

interface DataObject {
	[date: string]: number;
}

interface JsonResponse {
	data: DataObject;
	meta: Meta;
}

interface Emotion {
	name: string;
	emoji: string;
	description: string;
}

interface EmotionsData {
	emotions: {
		[key: string]: Emotion;
	};
}

interface CSVRow extends GridValidRowModel {
	[key: string]: string | number;
}

function safeDecodeBase64(encodedString: string, part = 'all'): string {
	try {
		encodedString = encodedString.split("/")[0].replace('_', '/').replace('-', '+');
		if (part === 'filename')
			return atob(encodedString).split("[")[0];
		else if (part === 'timestamp')
			return atob(encodedString).split("[")[1].split(']')[0];
		else if (part === 'extension')
			return atob(encodedString).split(".")[1];
		else
			return atob(encodedString);
	} catch (error) {
		console.error('Error decoding base64:', error);
		return 'Invalid';
	}
}

let resultsText = '';


function Explore() {

	const resultsDataToExplore = useLocation().state.key;
	const [openResults, setOpenResults] = React.useState(false);
	const [rows, setRows] = useState<GridRowsProp<CSVRow>>([]);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [sentenceRows, setSentenceRows] = useState<GridRowsProp<CSVRow>>([]);
	const [sentenceColumns, setSentenceColumns] = useState<GridColDef[]>([]);
	const [emotions, setEmotions] = useState<EmotionsData | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [period, setPeriod] = useState<string>('');
	const [value, setValue] = React.useState(0);
	const [expandRows, setexpandRows] = useState<boolean>(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setexpandRows(event.target.checked);
	};

	const getRowHeight = (params: any) => {
		return expandRows ? 'auto' : 52;
	};


	// Effect to fetch the data on component mount
	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch('/emotions.json');
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			} else {
				const result: EmotionsData = await response.json();
				setEmotions(result);
			}

		};

		fetchData();
	}, []); // Empty dependency array ensures this effect runs once on mount

	// Effect to log the emotions state when it changes
	useEffect(() => {
		},
		[emotions]);

	useEffect(() => {
		// Run list_files initially when the component mounts
		fetchTopicResults(resultsDataToExplore).then(r => {
		});
		fetchSentenceResults(resultsDataToExplore).then(r => {
		});

	}, [emotions]); // Empty

	const Tableau10 = [
		'#4e79a7',
		'#f28e2c',
		'#e15759',
		'#76b7b2',
		'#59a14f',
		'#edc949',
		'#af7aa1',
		'#ff9da7',
		'#9c755f',
		'#bab0ab',
	];

	// const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const file = event.target.files?.[0];
	// 	if (file) {
	// 		const reader = new FileReader();
	// 		reader.onload = (e) => {
	// 			if (e.target) {
	// 				const content = e.target.result as string;
	// 				const jsonContent: EmotionsData = JSON.parse(content);
	// 				setEmotions(jsonContent);
	// 			}
	// 		};
	// 		reader.readAsText(file);
	// 	}
	// };

	const findIndexByTitle = (items: string[] = [], title: string): number => {
		return items.findIndex(item => {
			return item === title;
		});
	};


	const descriptionElementRef = React.useRef(null);

	React.useEffect(() => {
		if (openResults) {
			const {current: descriptionElement} = descriptionElementRef;
			// if (descriptionElement !== null) {
			// 	descriptionElement.focus();
			// }
		}
	}, [openResults]);


	const getRowData = (json_string: string): number[] => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field

		const data: DataObject = parsedJson.data;
		const sortedArray = Object.entries(data)
			.sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB)) // Sort by keys
			.map(([_, value]) => value); // Extract values
		// return valuesArray;
		return sortedArray;
	};

	const getRowLabels = (json_string: string): string[] => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);

		// Extract the "data" field
		const data = parsedJson.data;

		// Sort the keys numerically and return them as an array of strings
		const sortedKeys = Object.keys(data)
			.sort((a, b) => parseInt(a) - parseInt(b)); // Sort the keys numerically

		return sortedKeys;
	};

	const getAxisMax = (json_string: string): number => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field

		const meta: Meta = parsedJson.meta;
		// return valuesArray;
		return meta.max_value;
	};

	const getPeriod = (json_string: string): string => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field

		const meta: Meta = parsedJson.meta;
		// return valuesArray;
		return meta.period;
	};

	let sparklineMax = 0;
	let barChartMax = 0;
	const fetchTopicResults = async (key: string) => {

		const skey = key.split('_all.zip')[0] + "_topics.csv";
		try {
			const downloadResult = await downloadData({
				key: skey,
				options: {
					accessLevel: 'private', // access level of the file being downloaded
				}
			}).result;


			resultsText = await downloadResult.body.text();

			readString<CSVRow>(resultsText, {
				header: true,
				quoteChar: '"',
				skipEmptyLines: "greedy",

				complete: (results) => {
					const data = results.data;
					const keys = results.meta.fields;
					if (data.length > 0) {
						setPeriod(getPeriod(data![0]['Topic Trend'].toString()));
						const firstColumns = keys!.slice(0, 6);
						const cols: GridColDef[] = firstColumns.map((key) => ({
							field: key,
							type: key === 'Topic ID' || key === 'Ticket Count' ? 'number' : 'string',
							width: key === 'sparkline' || key === 'Topic Key Emotion' || key === 'Topic Key Product' ? 175 : key === 'Topic Title' ? 350 : key === 'Topic Summary' ? 450 : key === 'Topic ID' ? 50 : key === 'Ticket Count' ? 100 : 150, // Adjust width for sparkline and emotion
							headerName: key === 'Topic ID' ? 'ID' : key === 'Topic Title' ? 'Title' : key === 'Ticket Count' ? 'Count' : key === 'Topic Key Product' ? 'Product' : key === 'Topic Key Emotion' ? 'Emotion' : key === 'Topic Summary' ? 'Summary' : key,
							renderCell: key === 'sparkline'
								? renderSparkline
								: key === 'Topic Key Emotion'
									? renderEmotion
									: key === 'Topic Key Product'
										? renderProduct
										: key === 'Ticket Count'
											? renderIncidence
											: renderText
						}));


						const rowData = data.map((row, index) => {
							const sparklineValues = getRowData(row['Topic Trend'].toString());
							const sparklineLabels = getRowLabels(row['Topic Trend'].toString());
							sparklineMax = getAxisMax(row['Topic Trend'].toString());
							barChartMax = Number(row['Ticket Count']) > barChartMax ? Number(row['Ticket Count']) : barChartMax ;

							if (!('id' in row)) {
								row.id = index; // Add id if not present
							}
							return {
								...row,
								sparkline: sparklineValues.join(','), // Create a single sparkline column
								labels: sparklineLabels.join(','),
							};
						});


						cols.push({
							field: 'sparkline',
							headerName: period + ' Trend',
							width: 200,
							renderCell: renderSparkline,
						})

						setColumns(cols);
						setRows(rowData);
					}
				},
			});

		} catch (error) {
			console.log(`Error downloading ${skey}: `, error);
			alert("No topics found");
		}
		return "No text";

	};

	const fetchSentenceResults = async (key: string) => {

		const skey = key.split('_all.zip')[0] + "_sentences.csv";
		try {
			const downloadResult = await downloadData({
				key: skey,
				options: {
					accessLevel: 'private', // access level of the file being downloaded
				}
			}).result;


			resultsText = await downloadResult.body.text();

			readString<CSVRow>(resultsText, {
				header: true,
				quoteChar: '"',
				skipEmptyLines: "greedy",

				complete: (results) => {
					const data = results.data;
					const keys = results.meta.fields;
					if (data.length > 0) {
						const firstColumns = keys!;
						const cols: GridColDef[] = firstColumns.map((key) => ({

							field: key,
							type: key === 'Topic ID' || key === 'Ticket Count' || key === 'Ticket ID' ? 'number' : 'string',
							width: key === 'Topic Key Emotion' || key === 'Topic Key Product' || key === 'Ticket Products' || key === 'Ticket Emotions'
								? 150
								: key === 'Topic Title'
									? 350
									: key === 'Topic Summary' || key === 'Ticket Key Sentences'
										? 450
										: key === 'Ticket Description'
											? 600
											: key === 'Topic ID'
												? 100
												: key === 'Ticket Count'
													? 150
													: 100, // Adjust width for sparkline and emotion
							// headerName : key === 'Ticket ID' ? 'ID' : key,
							renderCell: key === 'Topic Key Emotion'
								? renderEmotion
								: key === 'Topic Key Product' || key.includes("Tag")
									? renderProduct
									: key.includes("Sentences")
										? renderList
										: key === 'Ticket Products'
											? renderDictionary
											: key === 'Ticket Emotions'
												? renderEmotionDictionary
													: renderText,
						}));


						const rowData = data.map((row, index) => {
							if (!('id' in row)) {
								row.id = index; // Add id if not present
							}
							return {
								...row,
							};
						});
						setSentenceColumns(cols);
						setSentenceRows(rowData);
					}
				},
			});

		} catch (error) {
			console.log(`Error downloading ${skey}: `, error);
			alert("No topics found");
		}
		return "No text";

	};


	const renderSparkline = (params: GridRenderCellParams) => {
		const values = params.row.sparkline ? params.row.sparkline.split(',').map(Number) : [];
		const labels = params.row.labels ? params.row.labels.split(',').map(String) : [];
		return (
			<div style={{width: '100%', height: '100%'}}>
				<BarChart
					leftAxis={null}
					bottomAxis={null}
					xAxis={[
						{
							scaleType: 'band',
							data: labels,
						},
					]}
					series={[
						{
							data: values,
						},
					]}

					// height={45}
					yAxis={[
						{
							max: sparklineMax,
						},
					]}
					width={200}
					margin={{top: 1, bottom: 1, left: 0, right: 10}}
				/>
			</div>
		);
	};

	const renderIncidence = (params: GridRenderCellParams) => {
		const values = params.row['Ticket Count'] ? params.row['Ticket Count'] : 0;
		return (
			<div style={{width: '100%', height: '100%'}}>
				<BarChart
					borderRadius={10}
					layout="horizontal"
					leftAxis={null}
					bottomAxis={null}
					series={[
						{
							data: [values],
						},
					]}
					xAxis={[
						{
							max: barChartMax,
						},
					]}

					width={100}

					barLabel={'value'}
					margin={{top: 2, bottom: 2, left: 2, right: 12}}
				/>
			</div>
		);
	};

	const renderEmotion = (params: GridRenderCellParams) => {
		const emotionName = params.value;
		const emoji = emotions?.emotions[emotionName]?.emoji || '⏳'; // Default to ❓ if not found
		return <Chip size='small' sx={{margin: 1}} label={emoji + " " + emotionName}/>;
	};

	const renderProduct = (params: GridRenderCellParams) => {
		const productName = params.value;
		return <Chip size='small' sx={{margin: 1}} label={productName}/>;
	};

	const renderText = (params: GridRenderCellParams) => {
		const productName = params.value;
		return <Typography variant="body2" sx={{margin: 1}}>{productName}</Typography>;
	};

	const renderList = (params: GridRenderCellParams) => {
		const listString = params.value;
		const cleanedString = listString.slice(2, -2); // Removes the surrounding brackets and quotes
		const listItems: string[] = cleanedString.split("', '");
		return (
			<div>
				<List dense={true}>
					{listItems.map((item, index) => (
						<ListItem>
							<ListItemText key={index} primary={item}/>
						</ListItem>
					))}
				</List>
			</div>
		);
	};

	const renderEmotionDictionary = (params: GridRenderCellParams) => {
		const string = params.value;
		const parsedObject = JSON.parse(string.replace(/'/g, '"'));
		const keys: string[] = Object.keys(parsedObject);
		return (
			<div>
				{keys.map((key: string, index: number) => (
					<Chip sx={{margin: 1}} size='small' label={emotions?.emotions[key]?.emoji + " " + key}/>
				))}
			</div>
		);
	};

	const renderDictionary = (params: GridRenderCellParams) => {
		const string = params.value;
		const parsedObject = JSON.parse(string.replace(/'/g, '"'));

		// Extract keys from the object
		const keys: string[] = Object.keys(parsedObject);

		return (
			<div>
				{keys.map((key: string, index: number) => (
					<Chip sx={{margin: 1}} size='small' key={index} label={key}/>
				))}
			</div>
		);
	};


	const renderTicketCount = (params: GridRenderCellParams) => {
		const ticketCountStr = params.value;
		return parseInt(ticketCountStr);
	};

	const ConvertTimestampToDate = (timestamp: string): Date => {
		// Split the timestamp into its components
		const [year, month, day, hour, minute, second, millisecond] = timestamp.split('-').map(Number);
		// Create a new Date object
		return new Date(year, month - 1, day, hour, minute, second, millisecond);
	}

	const formatDate = (date: Date): string => {
		// // Split the timestamp into its components
		// const [year, month, day, hour, minute, second, millisecond] = timestamp.split('-').map(Number);
		//
		// // Create a new Date object
		// const date = new Date(year, month - 1, day, hour, minute, second, millisecond);
		//
		// Get today's date and yesterday's date
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		// Determine the day portion
		let dayPortion: string;
		if (date >= today) {
			dayPortion = 'Today';
		} else if (date >= yesterday) {
			dayPortion = 'Yesterday';
		} else {
			dayPortion = date.toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		}

		// Format the time portion
		const timePortion = date.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true,
		});

		return `${dayPortion} at ${timePortion}`;
	};

	const download_handle = async (fileKey: string) => {
		try {
			const getUrlResult = await getUrl({

				key: fileKey,
				options: {
					accessLevel: 'private',
					validateObjectExistence: false,
					expiresIn: 20,
				},
			});

			// Create a temporary anchor element
			const anchor = document.createElement('a');
			anchor.href = getUrlResult.url.href; // Use .href property
			anchor.download = fileKey.split("/").pop() || 'downloaded_file'; // Specify the filename for the download

			// Trigger a click event on the anchor element
			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		} catch (error) {
			console.log('Error ', error);
		}
	};


	return <>
		<div className={"top_nav"}>
			<span className="page_path"> <a href="/"><HomeIcon className={'home-icon'}/>&nbsp;Home</a> / <a
				href="/download">Results</a> / Explore data</span>
			<CreditsComponent creditWarning={50}/>
		</div>
		<div className={"main"}>

			{/*<h1>Explore data</h1>*/}
			{/*Desktop view*/}
			<Box sx={{width: '100%'}}>

				<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
					<Tabs value={value} textColor='inherit' indicatorColor='primary' onChange={handleChange}>
						<Tab icon={<PieChartIcon />} label="Summary" {...a11yProps(0)} />
						<Tab icon={<BarChartIcon />} label="Trends" {...a11yProps(1)} />
						<Tab icon={<ViewListIcon />} label="TIckets" {...a11yProps(2)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
					<h1>Top {rows.length > 10 ? 10 : rows.length} Ticket Drivers</h1>
					{/*<small>Date range: {minDate} to {maxDate}</small>*/}
					<PieChart
						margin={{top: 30, bottom: 30, left: 0, right: 600}}
						colors={Tableau10}
						series={[
							{
								data: rows.slice(0, 10).map((pieRow, pieIndex: number) =>
									({
										id: pieIndex,
										value: Number(pieRow['Ticket Count']),
										label: (pieIndex+1) + ". " + pieRow['Topic Key Product'].toString(),
										label2: pieRow['Topic Key Product'].toString(),
									})),
								highlightScope: {faded: 'global', highlighted: 'item'},
								arcLabelMinAngle: 30,
								arcLabel: (item) => `${item.label2} (${item.value})`,
								faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
							},
						]}
						height={700}
						slotProps={{
							legend: {
								direction: 'column',
								position: {
									vertical: 'middle',
									horizontal: 'right',
								},
								itemMarkWidth: 10,
								itemMarkHeight: 10,
								markGap: 10,
								itemGap:20,
							},
						}}
						sx={{
							[`& .${pieArcLabelClasses.root}`]: {
								fill: 'white',
								fontWeight: 'bold',
							},
						}}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={value} index={1}>
					<Box display="flex"
					     justifyContent="flex-end"
					     alignItems="flex-end">
						<FormControlLabel
							control={<Switch size="small" defaultChecked={expandRows} onChange={handleToggleSwitch}/>}
							label="Auto Row Height"/>
					</Box>

					<div style={{
						height: 'calc(100% - 125px)',
						width: 'calc(100% - 50px)',
						display: 'flex',
						position: 'absolute',
						// display: 'flex',
						// flexDi100rection: 'column',
					}}>
						<DataGrid
							columns={columns}
							rows={rows}
							slots={{toolbar: GridToolbar}}
							pageSizeOptions={[5, 10, 20, 50, 100]}
							checkboxSelection={true}
							disableRowSelectionOnClick={false}
							getRowHeight={getRowHeight}

						/>
					</div>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					<Box display="flex"
					     justifyContent="flex-end"
					     alignItems="flex-end">
						<FormControlLabel
							control={<Switch size="small" defaultChecked={expandRows} onChange={handleToggleSwitch}/>}
							label="Auto Row Height"/>
					</Box>

					<div style={{
						height: 'calc(100% - 125px)',
						width: 'calc(100% - 50px)',
						display: 'flex',
						position: 'absolute',
					}}>
						<DataGrid
							initialState={{
								columns: {
									columnVisibilityModel: {
										// Hide columns status and traderName, the other columns will remain visible
										'Ticket ID': true,
										'Topic ID': true,
										'Topic Title': false,
										'Topic Summary': false,
										'Ticket Count': false,
										'Topic Key Product': false,
										'Topic Key Emotion': false,
										'Ticket Date': true,
										'Ticket Description': true,
										'Ticket Key Sentences': false,
										'Ticket Products': true,
										'Ticket Emotions': true,
									},
								},
							}}
							columns={sentenceColumns}
							rows={sentenceRows}
							slots={{toolbar: GridToolbar}}
							pageSizeOptions={[5, 10, 20, 50, 100]}
							checkboxSelection={true}
							disableRowSelectionOnClick={true}
							getRowHeight={getRowHeight}
						/>
					</div>
				</CustomTabPanel>

			</Box>
		</div>
		<div className={"bottom_nav"}>
			<div className={'button-pair'}>
				<Link to="/download">
					<button className={'custom_button-secondary'}>Back</button>
				</Link>
				<Link to="/">
					<button className={'custom_button'}>Home</button>
				</Link>
			</div>
		</div>


	</>


}

export default Explore;
