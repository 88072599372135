import React, {useEffect, useState} from 'react';
import './styles/App.scss';
import './styles/components/custom_buttons.scss'
import '@aws-amplify/ui-react/styles.css';
import './styles/components/sidebar.scss'
import logo from './static/xx-logo-with-title-020629.png'
import logo_small from './static/voxx-logo-small.png'
import logo_login from './static/xx-logo-login.png'
import {useTheme, View, Heading, Button, useAuthenticator} from "@aws-amplify/ui-react";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { BrowserRouter, Routes, Route} from "react-router-dom";

import UploadPage from "./pages/uploading";
import Home from "./pages/home";
import DownloadPage from "./pages/download";
import Settings from "./pages/settings"
import Explore from "./pages/explore"
import PaymentSuccess from "./pages/payment_success";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";


const components = {
	Header() {
		const {tokens} = useTheme();

		return (
            <View     >
				<img
					alt="Amplify logo"
					src={logo_login}
                    className={"logo-center"}
				/>
			</View>
		);
	},



        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Sign in
                    </Heading>
                );
            },
            Footer() {
                const { toForgotPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toForgotPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot your password?
                        </Button>
                    </View>
                );
            },
        },

        SignUp: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Create an account
                    </Heading>
                );
            },
        },


        ConfirmSignIn: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <div></div>;
            },
        },
    SetupTotp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <div></div>;
        },
    },
    ConfirmResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <div></div>;
        },
    },
    ConfirmSignUp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <div></div>;
        },
    },
        ForgotPassword: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <div></div>;
            },
        },

    };

const formFields = {
    signIn: {
        username: {
            placeholder: 'Enter your email',
            order: 1,
        },
    },
    signUp: {
        email: {
            placeholder: 'Enter your email',
            order:1
        },

        password: {
            label: 'Password:',
            placeholder: 'Enter your Password:',
            isRequired: false,
            order: 2,
        },
        confirm_password: {
            label: 'Confirm Password:',
            order: 3,
        },
    },
    forceNewPassword: {
        password: {
            placeholder: 'Enter your Password:',
        },
    },
    forgotPassword: {
        username: {
            placeholder: 'Enter your email:',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
        },
        confirm_password: {
            placeholder: 'Enter your Password Please:',
        },
    },
    setupTotp: {
        QR: {
            totpIssuer: 'test issuer',
            totpUsername: 'amplify_qr_test_user',
        },
        confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
};


export function App({signOut, user}: WithAuthenticatorProps) {



    const [userId, setUserId] = useState<string | null>(null);
    const [tokens, setTokens] = useState<any>('');
    const [identityId, setIdentityId] = useState<any>('');

    useEffect(() => {
        async function currentAuthenticatedUser() {
            try {
                const { userId } = await getCurrentUser();
				setUserId(userId);


            } catch (err) {
                console.log(err);
            }
        }

        const fetchUserDetails = async () => {
            try {
                const fetchedTokens = await fetchAuthSession({ forceRefresh: true });
                // const newToken = fetchedTokens.credentials?.sessionToken
                const newIdentityid = fetchedTokens.identityId
				// const session = await Auth.currentSession();
				// const accessToken = session.accessToken.jwtToken;
				const cog = fetchedTokens.tokens;
				const rawToken = cog?.idToken?.toString();


				setTokens(rawToken);
                setIdentityId(newIdentityid);
            } catch (err) {
                console.log(err);
            }
        };
        currentAuthenticatedUser();
        fetchUserDetails();
    }, []);




    // console.log(cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage));


	return (
		<Authenticator  formFields={formFields} components={components}>
			{({ signOut, user }) => (
		<div className="App">
			{/*<h1>{user?.username}</h1>*/}
			<div className={"hidden-mobile"}>

				<div className={'sidebar'}>
					<a href="/">
						<img src={logo} className = 'voxxify-logo' alt="Voxxify logo"/>
					</a>
					<a href='/'>
						<div className={'route'}>
							<p><HomeOutlinedIcon className={'icon'}/></p>
							<p>Home</p>
						</div>
					</a>

					<a href='/upload'>
						<div className={'route'}>
							<p><CloudUploadOutlinedIcon className={'icon'}/></p>
							<p>Analyze</p>
						</div>
					</a>

					<a href='/download'>
						<div className={'route'}>
							<p><BarChartIcon className={'icon'}/></p>
							<p>Results</p>
						</div>
					</a>

					<a href='/settings'>
						<div className={'route'}>
							<p><StorefrontOutlinedIcon className={'icon'}/></p>
							<p>Buy Credits</p>
						</div>
					</a>

					<a href = '/' onClick={signOut}>

						<div className={'route-logout'}>
							<p><LogoutOutlinedIcon className={'icon'}/></p>
							<p>Logout</p>
						</div>
					</a>
				</div>
			</div>

			<div className={"hidden-desktop"}>

				<div className={'sidebar'}>
					<div className={'logo-holder'}>
						<a href="/" >
						<img src={logo_small}  className={'voxxify-logo'} alt="Voxxify logo"/>
					</a>
					</div>
					<div className={'top-menu'}>
					{/*<div className={'route'}>*/}
					{/*	<a href='/'>*/}
					{/*		Home*/}
					{/*	</a>*/}
					{/*</div>*/}
					<div className={'route'}>
						<a href='/upload'>
							Analyze
						</a>
					</div>
					<div className={'route'}>
						<a href='/download'>
							Results
						</a>
					</div>
					<div className={'route'}>
						<a href='/settings'>
							Credits
						</a>
					</div>
					<div className={'route'}>
						<button className={'logout'} onClick={signOut}>
							Logout
						</button>
					</div>
				</div>
				</div>
			</div>

			{/*<Divider size="large" />*/
			}
			{/*<UploadPage />*/}
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />}/>
					<Route path="/upload" element={<UploadPage userId={userId as string} tokens={tokens as string} identityId={identityId as string}/>}/>
					<Route path="/download" element={<DownloadPage />}/>
					<Route path="/settings" element={<Settings userId={userId as string} tokens={tokens as string}/>}/>
					<Route path="/payment_success" element={<PaymentSuccess />}/>
                    <Route path="/explore" element={<Explore />}/>

				</Routes>
			</BrowserRouter>


		</div>
			)}
		</Authenticator>
	);
}

export default App;


