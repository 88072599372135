import {Link, useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import '../styles/components/custom_buttons.scss'
import '../styles/settings.scss'
import '../styles/common.scss'
import HomeIcon from '@mui/icons-material/Home';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import env from "react-dotenv";
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import {Tooltip} from "@mui/material";
import CreditsComponent from "../ components/creditscomp";
import useCredits from "../ components/getter";
import {signOut} from "aws-amplify/auth";



const Settings = ({ userId, tokens}: { userId: string; tokens: string}) => {
    const [creditsToPurchase, setCreditsToPurchase] = useState(100);
    const credits = useCredits() ?? -1
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const credits_param = searchParams.get('credits');
    const cred_sent = useState(true)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const min:number = 100;
    const max:number = 100000;
    // const step: number = 100;


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(event.target.value);
        setCreditsToPurchase(inputValue);

        // Check if value is within range
        setIsButtonDisabled(inputValue < min || inputValue > max || credits + inputValue > max);
    };

    const handleCheckout = async () => {
        try {
            // Send a POST request to your server to create the checkout session
            const response = await axios.post(`${env.API_URL}/create-checkout-session`, {
                clientReferenceId: userId, // Pass the userId as clientReferenceId
                creditsToPurchase: creditsToPurchase // Pass the creditsToPurchase value
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': tokens,
                }
            });

            // Check if the response status is successful (HTTP 200)
            if (response.status === 200) {
                // Redirect to the checkout session URL returned by the server
                window.location.href = response.data.url;
            } else {
                // Handle the case where the server response is not as expected
                console.error('Unexpected server response:', response);
                alert('An error occurred during checkout. Please try again later.');
            }
        } catch (error:any) {
            // Handle any errors that occur during the POST request
            console.error('Error creating checkout session:', error);
            if (error.response.status === 403) {
                signOut()
            }
        }
    };
    useEffect(() => {
        // Run list_files initially when the component mounts
        if (cred_sent) {
            setCreditsToPurchase(Number(credits_param))
            if (Number(credits_param) < min){
                setCreditsToPurchase(min)
            }
        } else {
            return;
        }


    }, []);



    return (
        <div>
            <div className={"top_nav"}>
                <span className="page_path"><a href = "/"><HomeIcon className={'home-icon'} />&nbsp;Home</a> / Buy Credits</span>
                <CreditsComponent creditWarning={50} />
            </div>
            <div className={'main'}>
                <h1>Credit store</h1>
                <div className={'table-div-upload'}>
                <div className={'top-up'}>
                    <h1>Buy Credits</h1>
                    <div>
                        <FormControl className={'top-up-amount'} sx={{m: 1}}>
                            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start"></InputAdornment>}
                                label="Amount"
                                size='small'
                                type="number"
                                value={creditsToPurchase}
                                onChange={handleChange}
                                inputProps={{
                                    min: {min}, // set the minimum value
                                    max: {max},
                                    step: 100, // set the step value
                                }}
                            />
                            <FormHelperText id="my-helper-text">Range {min} to {max-credits} credits
                                <Tooltip title={"Maximum permitted balance is " +  max + " credits. Please email sales@voxxify.com if you need more."}>
                                    <InfoIcon style={{margin:'1px',color: 'blue', fontSize: '15px'}}/>
                                </Tooltip>
                            </FormHelperText>

                        </FormControl>
                    </div>


                    );
                    <p>
                        <button className={'top-up-button'} onClick={handleCheckout} disabled={isButtonDisabled}>Buy now</button>
                    </p>

                    {creditsToPurchase + credits > max ? (
                        <Alert severity="error">Maximum purchase is {max-credits} credits.</Alert>
                    ) : creditsToPurchase < min ? (
                            <Alert severity="error">Minimum purchase is {min} credits.</Alert>
                        ) : null}

                </div>
                </div>
            </div>
            <div className={"bottom_nav"}>
                <div className={'button-pair'}>
                    <a href ="/">
                        <button className={'custom_button-secondary'}>Home</button>
                    </a>
                    <Link to="/upload">
                        <button className={'custom_button'}>New Upload</button>
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default Settings;
