import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {downloadData, getUrl, list, remove} from 'aws-amplify/storage';
import "../styles/download.scss"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
import Chip from "@mui/material/Chip";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import CreditsComponent from "../ components/creditscomp";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CustomConfirmation from '../ components/custom_confirmation';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CustomProgressCircle from '../ components/custom_progress_circle'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {BarChart} from '@mui/x-charts/BarChart';
import Papa from "papaparse";
import {ParseResult} from 'papaparse';
import FaceIcon from '@mui/icons-material/Face';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CategoryIcon from '@mui/icons-material/Category';
import {PieChart} from '@mui/x-charts/PieChart';
import CircleIcon from "@mui/icons-material/Circle";
import ViewListIcon from '@mui/icons-material/ViewList';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import Tooltip from '@mui/material/Tooltip';
import CustomAlert from "../ components/custom_alert";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

interface TopLines {
	columnNames: string[];
	dataRows: string[];
}

// Define interfaces for the structure of your JSON data
interface Meta {
	period: string;
	min_date: string;
	max_date: string;
	max_value: number;
}

interface DataObject {
	[date: string]: number;
}

interface JsonResponse {
	data: DataObject;
	meta: Meta;
}

interface RowData {
	id: number;
	topic_id: string;
	title: string;
	summary: string;
	count: string;
	emotion: string;
	product: string;
	trend: string;
	// Add other columns you want to include
}

function safeDecodeBase64(encodedString: string, part = 'all'): string {
	try {
		encodedString = encodedString.split("/")[0].replace('_', '/').replace('-', '+');
		if (part === 'filename')
			return atob(encodedString).split("[")[0];
		else if (part === 'timestamp')
			return atob(encodedString).split("[")[1].split(']')[0];
		else if (part === 'extension')
			return atob(encodedString).split(".")[1];
		else
			return atob(encodedString);
	} catch (error) {
		console.error('Error decoding base64:', error);
		return 'Invalid';
	}
}

// let dgColumns: GridColDef<[number]>[] = [
// 	{ field: 'id', headerName: 'ID', width: 90 },
// 	{
// 		field: 'firstName',
// 		headerName: 'First name',
// 		width: 150,
// 		editable: true,
// 	},
// 	];


function Download() {
	const [openResults, setOpenResults] = React.useState(false);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [topLines, setTopLines] = useState<TopLines>({columnNames: [], dataRows: []});
	const [rows, setRows] = useState<RowData[]>([]);
	const [dialogTitle, setDialogTitle] = React.useState('');
	const [maxValue, setMaxValue] = React.useState(0);
	const [minDate, setMinDate] = React.useState('');
	const [maxDate, setMaxDate] = React.useState('');
	const [period, setPeriod] = React.useState('');
	const [resultsKey, setResultsKey] = React.useState('');
	const Tableau10 = [
		'#4e79a7',
		'#f28e2c',
		'#e15759',
		'#76b7b2',
		'#59a14f',
		'#edc949',
		'#af7aa1',
		'#ff9da7',
		'#9c755f',
		'#bab0ab',
	];


	const handleResultsClick = (key: string) => {
		fetchResults(key)
			.then(r => {
					setOpenResults(true);
					setResultsKey(key);
				}
			)
			.catch(err => {
					console.error(err);
					setOpenResults(false);
				}
			);
	};

	const handleResultsClose = () => {
		setOpenResults(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (openResults) {
			const {current: descriptionElement} = descriptionElementRef;
			// if (descriptionElement !== null) {
			// 	descriptionElement.focus();
			// }
		}
	}, [openResults]);

	const fetchResults = async (key: string) => {
		const skey = key.split('_all.zip')[0] + "_topics.csv";
		setDialogTitle(": " + safeDecodeBase64(skey).split("[")[0])
		try {
			const downloadResult = await downloadData({
				key: skey,
				options: {
					accessLevel: 'private', // access level of the file being downloaded
				}
			}).result;

			const resultsText = await downloadResult.body.text();

			Papa.parse(resultsText, {
				header: true,
				skipEmptyLines: true,
				complete: (result: ParseResult<string[]>) => {
					const parsedData = result.data as { [key: string]: any }[];
					const formattedRows = parsedData.map((row, index) => ({
						id: index,
						topic_id: row['Topic ID'],
						title: row['Topic Title'],
						summary: row['Topic Summary'],
						count: row['Ticket Count'],
						emotion: row['Topic Key Emotion'],
						product: row['Topic Key Product'],
						trend: row['Topic Trend'],
					}));
					setRows(formattedRows);
					const meta: Meta = getMetaData(formattedRows[0]['trend'].toString());
					setMaxValue(meta.max_value);
					setMaxDate(meta.max_date);
					setMinDate(meta.min_date);
					setPeriod(meta.period);
					return ("Success");
				},
				error: (error: Error) => {
					console.error("Error parsing CSV string:", error);
					throw new Error("Error parsing CSV string:");
				}
			});
		} catch (error) {
			console.log(`Error downloading ${skey}: `, error);
			handleOpenAlertDialog();
			throw new Error("No topics found:");
		}


		return "No text";
	};

	const navigate = useNavigate();

	const exploreResults = async (key_param: string) => {
		const skey = key_param.split('_all.zip')[0] + "_topics.csv";
		try {
			const [getUrlResult] = await Promise.all([getUrl({
				key: skey,
				options: {
					accessLevel: 'private', // can be 'private', 'protected', or 'guest' but defaults to `guest`
					validateObjectExistence: true,  // Check if object exists before creating a URL
					expiresIn: 20, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
				},
			})]);
		} catch (error){
			handleOpenAlertDialog();
			return null;
		}
		navigate('/explore', { state: { key: key_param } });
	}


	const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	// const [fileProperties, setFileProperties] = useState<any | null>(null);
	const [fileList, setFileList] = useState<any[]>([]);

	const sortedFileList = [...fileList].sort((a, b) => {
		const extractTimestamp = (filename: string) => {
			const match = safeDecodeBase64(filename).match(/\[(\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}-\d{3})\]/);
			return match ? match[1] : '';
		};

		const convertToDate = (timestamp: string) => {
			const [year, month, day, hour, minute, second, millisecond] = timestamp.split('-').map(Number);
			return new Date(year, month - 1, day, hour, minute, second, millisecond);
		};

		const dateA = convertToDate(extractTimestamp(a.key));
		const dateB = convertToDate(extractTimestamp(b.key));

		return dateB.getTime() - dateA.getTime();
	});

	const ConvertTimestampToDate = (timestamp: string): Date => {
		// Split the timestamp into its components
		const [year, month, day, hour, minute, second, millisecond] = timestamp.split('-').map(Number);
		// Create a new Date object
		return new Date(year, month - 1, day, hour, minute, second, millisecond);
	}

	const formatDate = (date: Date): string => {
		// // Split the timestamp into its components
		// const [year, month, day, hour, minute, second, millisecond] = timestamp.split('-').map(Number);
		//
		// // Create a new Date object
		// const date = new Date(year, month - 1, day, hour, minute, second, millisecond);
		//
		// Get today's date and yesterday's date
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		// Determine the day portion
		let dayPortion: string;
		if (date >= today) {
			dayPortion = 'Today';
		} else if (date >= yesterday) {
			dayPortion = 'Yesterday';
		} else {
			dayPortion = date.toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		}

		// Format the time portion
		const timePortion = date.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true,
		});

		return `${dayPortion} at ${timePortion}`;
	};
	const [dialogOpen, setDialogOpen] = useState(false);
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	const [currentFolderKey, setCurrentFolderKey] = useState<string>('');

	const handleOpenConfirmDel = (folderKey: string) => {
		setCurrentFolderKey(folderKey);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const handleOpenAlertDialog = () => {
		setAlertDialogOpen(true);
	};

	const handleCloseAlertDialog = () => {
		setAlertDialogOpen(false);
	};

	const handleAgree = async () => {
		if (currentFolderKey) {
			await delete_file(currentFolderKey);
		}
		handleCloseDialog();
	};

	const delete_file = async (folderKey: string) => {
		const start_key = folderKey.split("/");
		const sKey = start_key[0] + "/";
		try {
			// List all objects in the folder
			const folderContents = await list({
				prefix: sKey,
				options: {
					accessLevel: 'private',
					listAll: true,
				},
			});
			// Iterate through the objects in the folder and delete each one
			await Promise.all(
				folderContents.items.map(item =>
					remove({key: item.key, options: {accessLevel: 'private'}})
						.then(() => {
							// Successful deletion (optional logging)
							setSnackbarOpen(true);
							list_files();
						})
						.catch((error) => {
							// Handle deletion failure
							console.error("Failed to delete:", item.key, error);
						})
				)
			);
			// Update the file list after deletion
			await list_files();
		} catch (error) {
			console.log('Error:', error);
		}
	};

	const download_handle = async (fileKey: string) => {
		try {
			const getUrlResult = await getUrl({

				key: fileKey,
				options: {
					accessLevel: 'private',
					validateObjectExistence: false,
					expiresIn: 20,
				},
			});

			// Create a temporary anchor element
			const anchor = document.createElement('a');
			anchor.href = getUrlResult.url.href; // Use .href property
			anchor.download = fileKey.split("/").pop() || 'downloaded_file'; // Specify the filename for the download

			// Trigger a click event on the anchor element
			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
		} catch (error) {
			console.log('Error ', error);
		}
	};


	const list_files = async () => {

		try {
			const list_in_s3 = await list({
				options: {
					accessLevel: 'private',
					listAll: true
				}
			});

			interface Item {
				key: string;
				status: string;
			}

			// Filter items to include only those with keys containing "output" and not ending with "output/"
			const allItems: Item[] = list_in_s3.items
				.sort((a, b) => b.key.localeCompare(a.key))
				.map(item => {
					// Check if the key ends with ".zip" and change its status to "Complete"
					const analysisStatus =
						(item.key.endsWith(".zip") && item.key.includes("/output/")) ?
							"Complete" :
							(item.key.endsWith("errors.txt") && item.key.includes("/output/")) ?
								"Error" :
								(item.key.endsWith("status.txt") && item.key.includes("/output/")) ?
									"Analyzing" :
									item.key.includes("/input/") ?
										"Pending" :
										"Ignore";
					return {...item, status: analysisStatus};
				});

			// Retrieve the body of the status.txt file for each file key in S3
			const fetchStatusTexts = async (keys: string[]): Promise<{ [key: string]: string | null }> => {
				const statusTexts: { [key: string]: string | null } = {};

				await Promise.all(keys.map(async (key) => {
					try {

						const downloadResult = await downloadData({
							key: key,
							options: {
								accessLevel: 'private', // access level of the file being downloaded
							}
						}).result;


						const text = await downloadResult.body.text();


						if (text) {
							statusTexts[key] = text;
						} else {
							statusTexts[key] = 'Unknown';
						}
					} catch (error) {
						console.log(`Error downloading ${key}: `, error);
						statusTexts[key] = null;
					}
				}));
				return statusTexts;
			};

			// Get an array of keys for files with status "Analyzing"
			const analyzingKeys = allItems
				.filter(item => item.status === "Analyzing")
				.map(item => item.key.replace(/\.zip$|\.errors\.txt$/, '.status.txt'));


			// Fetch status texts for files with status "Analyzing"
			const statusTexts = await fetchStatusTexts(analyzingKeys);


			// Combine status texts with all items
			const updatedItems = allItems.map(item => {
				if (item.status === "Analyzing") {
					return {...item, statusText: statusTexts[item.key]};
				}
				return item;
			});

			// Filter and process items as before
			const filteredItems = updatedItems.reduce((acc: { [key: string]: Item }, item: Item) => {
				const key = item.key.split('/')[0];
				if (!acc[key] && ["Complete", "Error", "Analyzing", "Pending"].includes(item.status)) {
					acc[key] = item;
				}
				return acc;
			}, {});

			const filteredItemsArray = Object.values(filteredItems);

			if (filteredItemsArray.length === 0) {
				setFileList([]);
			} else {
				setFileList((filteredItemsArray));

			}
		} catch (error) {
			console.log('Error ', error);
		}
	};

	const getRowLabels = (json_string: string): string[] => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field
		const data: DataObject = parsedJson.data;

		// Get keys, sort them, and return
		const sortedKeys = Object.keys(data)
			.sort((a, b) => parseInt(a) - parseInt(b));

		return sortedKeys;
	};

	const getRowData = (json_string: string): number[] => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field

		const data: DataObject = parsedJson.data;
		const sortedArray = Object.entries(data)
			.sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB)) // Sort by keys
			.map(([_, value]) => value); // Extract values
		return sortedArray;
	};


	const getMetaData = (json_string: string): Meta => {
		// Parse JSON string
		const parsedJson: JsonResponse = JSON.parse(json_string);
		// Extract the "data" field

		const meta: Meta = parsedJson.meta;
		// return valuesArray;
		return (meta);
	};


	function statusTooltip(statusText: string) {
		switch (statusText) {
			case 'Complete':
				return 'Analysis complete';
			case  "Pending":
				return 'Initializing AI';
			case  'Analyzing':
				return 'Analyzing input file';
			case 'Reading':
				return 'Reading input file';
			case 'Translating':
				return 'Translating description column';
			case 'Splitting':
				return 'Splitting descriptions into sentences';
			case 'Sentiment':
				return 'Analyzing sentiment';
			case 'Products':
				return 'Discovering products';
			case 'Emotions':
				return 'Finding emotions in text';
			case 'Clustering':
				return 'Clustering sentences into topics';
			case 'Redacting':
				return 'Pseudonymizing text before sending to LLM';
			case 'Summarizing':
				return 'Generating topic summaries';
			case 'Writing':
				return 'Writing output files';
			default:
				return statusText;
		}
	}

	useEffect(() => {
		// Run list_files initially when the component mounts
		list_files().then(r => {
		});

		// Set up an interval to run list_files every minute
		const intervalId = setInterval(() => {
			list_files().then(r => {
			})
		}, 60000); // 60000 milliseconds = 1 minute

		// Clean up the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []); // Empty dependency array ensures that this effect runs only once

	/*<h2>#1 Ticket Driver:&nbsp;{topLines.dataRows.slice(4, 5)}&nbsp;({topLines.dataRows.slice(3, 4)} Tickets)</h2>*/
	// @ts-ignore
	return <>

		<div className={"top_nav"}>
			<span className="page_path"> <a href="/"><HomeIcon className={'home-icon'}/>&nbsp;Home</a> / Results</span>
			<CreditsComponent creditWarning={50}/>

		</div>
		<div className={"main"}>

			{/* Your other components */}

			<h1> Results</h1>
			{/*Desktop view*/}
			<div className={"table-div-desktop hidden-mobile"}>


				<div className={'section-header'}>
					<div className={'file-name-header'}><p>File analyzed</p></div>
					<div className={'file-date-header'}><p>Last updated</p></div>
					<div className={'file-download-size-header'}><p>Size</p></div>
					<div className={'file-download-status-header'}><p>Status</p></div>
					<div className={'file-download-action-header'}><p>Actions</p></div>
				</div>
				<div className={"download_table"}>
					{fileList.length < 1
						? <Stack spacing={1}>
							{/*For slow networks make sure page is only re-rendered when fileList is complete*/}

							{/* For other variants, adjust the size with `width` and `height` */}
							<Skeleton variant="rounded" height={70} sx={{bgcolor: 'white'}}/>
							<Skeleton variant="rounded" height={70} sx={{bgcolor: 'white'}}/>
							<Skeleton variant="rounded" height={70} sx={{bgcolor: 'white'}}/>
						</Stack>
						: sortedFileList.map((file, index) => <div className={'section'} onClick={() => list_files()}
						                                           key={index}>
							<div className={'file-download-name'}>
								<p><span>
                                    <div><span>{safeDecodeBase64(file.key, 'filename')}</span>
	                                    {/*<span><Chip*/}
	                                    {/*size='small'*/}
	                                    {/*label={safeDecodeBase64(file.key, 'extension')}/></span>*/}
                                    </div>
                                    <div className={'timestamp'}><CloudUploadOutlinedIcon
	                                    fontSize={'small'}/>&nbsp;{formatDate(ConvertTimestampToDate(safeDecodeBase64(file.key, 'timestamp')))}</div>
                                </span>

								</p>
							</div>
							{/*.split("/")[1]*/}
							<div className={'file-download-date'}>
								<p>{formatDate(file.lastModified)}</p></div>
							<div className={'file-download-size'}>
								<p>{file.status === "Complete" ? (file.size >= (1048576) ? (file.size / (1048576)).toFixed(0) + " MB" : file.size >= 1024 ? (file.size / 1024).toFixed(0) + " KB" : file.size.toFixed(0) + " B") : "-"}</p>
							</div>
							<div className={'file-download-status'}><p>
								<Tooltip title={statusTooltip(file.statusText)}>
									<Chip size='small'
									      sx={{m: 1, display: 'inline-flex', position: 'relative'}}
									      color={file.status === "Complete" ? 'success' : file.status === "Error" ? 'error' : file.status === "Pending" ? 'default' : "info"}
									      variant="outlined"
									      label={file.statusText ? file.statusText : file.status}
									      icon={file.status === "Analyzing"
										      ? <CustomProgressCircle
											      value={file.statusText === 'Reading' ? 5
												      : file.statusText === 'Translating' ? 10
													      : file.statusText === 'Splitting' ? 20
														      : file.statusText === 'Sentiment' ? 25
															      : file.statusText === 'Products' ? 35
																      : file.statusText === 'Emotions' ? 60
																	      : file.statusText === 'Clustering' ? 70
																		      : file.statusText === 'Redacting' ? 80
																			      : file.statusText === 'Summarizing' ? 85
																				      : file.statusText === 'Writing' ? 95
																					      : 100}
											      size="1rem" thickness={6}/> :
										      file.status === "Complete" ? <SentimentSatisfiedAltIcon/> :
											      file.status === "Error" ? <SentimentVeryDissatisfiedIcon/> :
												      <HourglassEmptyRoundedIcon/>}/>
								</Tooltip>
							</p></div>
							<div className={'file-download-actions'}>
								{file.status === "Complete"
									?
									<span className={'file-download-actions'}>
										<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
										<Tooltip title="Download"><IconButton size="small" aria-label="download" color="inherit" onClick={() => download_handle(file.key)}><FileDownloadOutlinedIcon /></IconButton></Tooltip>
											<Tooltip title="Preview"><IconButton size="small" aria-label="preview" color="inherit" onClick={() => handleResultsClick(file.key)}><BarChartIcon /></IconButton></Tooltip>
											<Tooltip title="Explore"><IconButton size="small" aria-label="preview" color="inherit" onClick={() => exploreResults(file.key)}><QueryStatsIcon /></IconButton></Tooltip>
										<Tooltip title="Delete"><IconButton size="small" aria-label="delete" color="inherit" onClick={() => handleOpenConfirmDel(file.key)}><DeleteOutlineOutlinedIcon /></IconButton></Tooltip>
										</Stack>
										</span>

									:
									(file.status === "Analyzing" || file.status === "Pending")
										?
										<span className={'file-download-actions'}>
									<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
										<IconButton aria-label="download" size="small" disabled color="inherit"><FileDownloadOutlinedIcon /></IconButton>
										<IconButton aria-label="preview" size="small" disabled  color="inherit"><BarChartIcon /></IconButton>
										<IconButton aria-label="explore" size="small" disabled  color="inherit"><QueryStatsIcon /></IconButton>
										<IconButton aria-label="delete" size="small" disabled  color="inherit"><DeleteOutlineOutlinedIcon /></IconButton>
									</Stack>
									</span>
										:
										<span className={'file-download-actions'}>
									<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
										<IconButton aria-label="download" size="small" disabled color="inherit" ><ReplayOutlinedIcon /></IconButton>
										<IconButton aria-label="preview" size="small" disabled  color="inherit" ><BarChartIcon /></IconButton>
										<IconButton aria-label="explore" size="small" disabled  color="inherit" ><QueryStatsIcon /></IconButton>
										<IconButton aria-label="delete" size="small" color="error" onClick={() => handleOpenConfirmDel(file.key)}><DeleteOutlineOutlinedIcon /></IconButton>
									</Stack>
                                    </span>}
							</div>
						</div>)}
				</div>
			</div>
			{/*Mobile View*/}
			<div className={"table-div-mobile  hidden-desktop"}>
				<div className={"download_table"}>
					{sortedFileList.map((file, index) => <div className={'section'}>
						<div className={'file-download-header'}>
                            <span
	                            className={'file-download-name'}>
                                <b>{safeDecodeBase64(file.key, 'filename')}</b>
                            </span>
							{file.status === "Complete"
								?
								<span className={'file-download-actions'}>
										<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
											<Tooltip title="Download"><IconButton size="small" aria-label="download" color="inherit" onClick={() => download_handle(file.key)}><FileDownloadOutlinedIcon /></IconButton></Tooltip>
											<Tooltip title="Preview"><IconButton size="small" aria-label="preview" color="inherit" onClick={() => handleResultsClick(file.key)}><BarChartIcon /></IconButton></Tooltip>
											<Tooltip title="Explore"><IconButton size="small" aria-label="preview" color="inherit" onClick={() => exploreResults(file.key)}><QueryStatsIcon /></IconButton></Tooltip>
											<Tooltip title="Delete"><IconButton size="small" aria-label="delete" color="inherit" onClick={() => handleOpenConfirmDel(file.key)}><DeleteOutlineOutlinedIcon /></IconButton></Tooltip>
										</Stack>
								</span>
								:
								(file.status === "Analyzing" || file.status === "Pending")
									?
									<span className={'file-download-actions'}>
										<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
											<IconButton aria-label="download" size="small" disabled color="inherit"><FileDownloadOutlinedIcon /></IconButton>
											<IconButton aria-label="preview" size="small" disabled  color="inherit"><BarChartIcon /></IconButton>
											<IconButton aria-label="explore" size="small" disabled  color="inherit"><QueryStatsIcon /></IconButton>
											<IconButton aria-label="delete" size="small" disabled  color="inherit"><DeleteOutlineOutlinedIcon /></IconButton>
										</Stack>
									</span>
								:
									<span className={'file-download-actions'}>
										<Stack direction='row' justifyContent={'space-evenly'} alignItems={'middle'} spacing={0}>
											<IconButton aria-label="download" size="small" disabled color="inherit" ><ReplayOutlinedIcon /></IconButton>
											<IconButton aria-label="preview" size="small" disabled  color="inherit" ><BarChartIcon /></IconButton>
											<IconButton aria-label="explore" size="small" disabled  color="inherit" ><QueryStatsIcon /></IconButton>
											<IconButton aria-label="delete" size="small" color="error" onClick={() => handleOpenConfirmDel(file.key)}><DeleteOutlineOutlinedIcon /></IconButton>
										</Stack>
                                    </span>}
						</div>
						{/*.split("/")[1]*/}
						<span className={'file-download-details'}>
                            <div className={'timestamp'}>
                                <CloudUploadOutlinedIcon fontSize={'small'}/>
	                            &nbsp;
	                            {formatDate(ConvertTimestampToDate(safeDecodeBase64(file.key, 'timestamp')))}
                            </div>
							{/*<div>|</div>*/}
							{/*<div className={'file-download-size'}>{file.size / 1000 ^ 2} MB</div><div>|</div>*/}
							<div className={'file-download-status'}>
                                <Tooltip title={file.statusText}>
                                    <Chip size='small'
                                          color={file.status === "Complete" ? 'success' : file.status === "Error" ? 'error' : file.status === "Pending" ? 'default' : "info"}
                                          variant="outlined"
                                          label={file.statusText ? file.statusText : file.status}
                                          icon={file.status === "Analyzing"
	                                          ? <CustomProgressCircle
		                                          value={file.statusText === 'Reading' ? 5
			                                          : file.statusText === 'Translating' ? 10
				                                          : file.statusText === 'Splitting' ? 20
					                                          : file.statusText === 'Sentiment' ? 25
						                                          : file.statusText === 'Products' ? 35
							                                          : file.statusText === 'Emotions' ? 60
								                                          : file.statusText === 'Clustering' ? 70
									                                          : file.statusText === 'Redacting' ? 80
										                                          : file.statusText === 'Summarizing' ? 85
											                                          : file.statusText === 'Writing' ? 95
												                                          : 100}
		                                          size="1rem" thickness={5}/> :
	                                          file.status === "Complete" ? <SentimentSatisfiedAltIcon/> :
		                                          file.status === "Error" ? <SentimentVeryDissatisfiedIcon/> :
			                                          <HourglassEmptyRoundedIcon/>}/>
                                </Tooltip>
                            </div>
                        </span>
					</div>)}
				</div>
			</div>
		</div>
		<div className={"bottom_nav"}>
			<div className={'button-pair'}>
				<Link to="/">
					<button className={'custom_button-secondary'}>Home</button>
				</Link>
				<a href="/upload">
					<button className={'custom_button'}>New Upload</button>
				</a>
			</div>
		</div>
		<React.Fragment>
			<CustomConfirmation
				open={dialogOpen}
				alertTitle="Confirm File Deletion"
				alertDialogue={`Delete "${safeDecodeBase64(currentFolderKey!, 'filename')}" and related files?\nDeleting files is permanent and cannot be undone.`}
				onClose={handleCloseDialog}
				onAgree={handleAgree}/>
		</React.Fragment>
		<React.Fragment>
			<CustomAlert
				open={alertDialogOpen}
				alertTitle="No Topics"
				alertDialogue={`No topics were discovered in the uploaded data.`}
				onClose={handleCloseAlertDialog}
				onAgree={handleCloseAlertDialog}></CustomAlert>

		</React.Fragment>

		<React.Fragment>
			<Dialog
				open={openResults}
				onClose={handleResultsClose}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">Results Summary<small>{dialogTitle}</small></DialogTitle>
				<DialogContent>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<h1>Top {rows.length > 10 ? 10 : rows.length} Ticket Drivers</h1>
						<small>Date range: {minDate} to {maxDate}</small>
						<PieChart
							margin={{top: 30, bottom: 30, left: 0, right: 200}}
							colors={Tableau10}
							series={[
								{
									data: rows.slice(0, 10).map((pieRow, pieIndex: number) =>
										({
											id: pieIndex,
											value: Number(pieRow['count']),
											label: (pieRow['topic_id']) + ". " + pieRow['product'],
										})),
									highlightScope: {faded: 'global', highlighted: 'item'},
									arcLabelMinAngle: 45,
									faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
								},
							]}
							height={250}
							slotProps={{
								legend: {
									direction: 'column',
									itemMarkWidth: 10,
									itemMarkHeight: 10,
									markGap: 10,
									itemGap: 5,
								}
							}}
						/>
						{
							rows.slice(0, 10).map((row, rowIndex) =>
								<div key={rowIndex}>
									<h3><CircleIcon fontSize={'small'}
									                sx={{paddingTop: '5px', color: Tableau10[rowIndex]}}/>
										{row['topic_id']}.
										{row['title']}</h3>

									<Chip size='small'
									      sx={{m: 1, display: 'inline-flex', position: 'relative'}}
									      label={row['product']}
									      icon={<CategoryIcon/>}/>
									<Chip size='small'
									      sx={{m: 1, display: 'inline-flex', position: 'relative'}}
									      label={row['count'] + " Tickets"}
									      icon={<SupportAgentIcon/>}/>
									<Chip size='small'
									      sx={{m: 1, display: 'inline-flex', position: 'relative'}}
									      label={row['emotion']}
									      icon={<FaceIcon/>}/>
									<Chip size={'small'}
									      sx={{m: 1, display: 'inline-flex', position: 'relative'}}
									      label={period}
									      icon={<BarChartIcon/>}/>

									{row['trend']
										?
										<BarChart
											xAxis={[
												{
													scaleType: 'band',
													tickSize: 10,
													data: getRowLabels(row['trend'].toString()),
												},
											]}
											series={[
												{
													data: getRowData(row['trend'].toString()),
													color: Tableau10[rowIndex],
												},
											]}
											yAxis={[
												{
													max: maxValue,
												},
											]}
											height={200}
											margin={{top: 20, bottom: 30, left: 40, right: 30}}
										/>
										:
										<></>
									}
									<p>{row['summary']}</p>

								</div>
							)
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleResultsClose}>Close</Button>
					<Button onClick={() => exploreResults(resultsKey)}>Explore</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
		<React.Fragment>
			<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
				<Alert
					onClose={handleSnackbarClose}
					severity="success"
					sx={{width: '100%'}}
				>
					Deleted.
				</Alert>
			</Snackbar>
		</React.Fragment>
	</>


}

export default Download;
