import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Papa from 'papaparse';

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.25)', // Purple color with 50% opacity
   zIndex: -1,
}));

interface Props {
  open: boolean;
  alertTitle: string;
  alertDialogue: string;
  onClose: () => void;
  onAgree: () => void;
}

const CustomConfirmation: React.FC<Props> = ({ open, alertTitle, alertDialogue, onClose, onAgree }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      slots={{ backdrop: CustomBackdrop }}
    >
      <DialogTitle id="alert-dialog-title">
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertDialogue}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAgree} autoFocus>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmation;
