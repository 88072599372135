//
import {Link} from "react-router-dom";
import React from 'react';
import '../styles/components/custom_buttons.scss'
import '../styles/settings.scss'
import '../styles/payment_success.scss'
import { useLocation } from 'react-router-dom';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HomeIcon from '@mui/icons-material/Home';
import CreditsComponent from "../ components/creditscomp";


import useCredits from "../ components/getter";
// import Settings from "./settings";

const Payment_success = () => {
    const credits = useCredits() ?? -1
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search) ?? 0;
    const purchasedCredits = searchParams.get('credits') ?? 0;


    return (
        <div>
            <div className={"top_nav"}>
                <span className="page_path"> <a href = "/"><HomeIcon className={'home-icon'} />&nbsp;Home</a> / Results</span>
                <CreditsComponent creditWarning={50} />
            </div>
            <div className={'main'}>
                <div className={"top-up"}>
                    <h1>Payment successful</h1>
                    <CheckCircleRoundedIcon className={"icon-payment-success"} style={{fontSize: '64'}}/>
                    <h3>Purchased credits: {purchasedCredits}</h3>
                    <h3>Credit balance: {credits}</h3>

                    <div className={'button-pair'}>
                        <Link to="/settings">
                            <button className={'custom_button-secondary'}>Buy More</button>
                        </Link>
                        <a href="/upload">
                            <button className={'custom_button'}>Use Credits</button>
                        </a>
                    </div>

                </div>
            </div>
            <div className={"bottom_nav"}>
                <div className={'button-pair'}>
                    <Link to="/">
                        <button className={'custom_button-secondary'}>Home</button>
                    </Link>
                    <a href="/upload">
                        <button className={'custom_button'}>New Upload</button>
                    </a>
                </div>
            </div>
        </div>

    );
};

export default Payment_success;
