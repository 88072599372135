// components/CreditsComponent.tsx
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCredits, selectCredits, selectCreditsStatus, selectCreditsError } from '../redux/slice/credits';
import { RootState, AppDispatch } from '../redux/store';
import { Chip, CircularProgress } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import CloudIcon from '@mui/icons-material/Cloud';

interface Props {
    creditWarning: number;
}

const CreditsComponent: React.FC<Props> = ({ creditWarning }) => {
    const dispatch = useDispatch<AppDispatch>();
    const credits = useSelector(selectCredits);
    const creditsStatus = useSelector(selectCreditsStatus);
    const creditsError = useSelector(selectCreditsError);

    useEffect(() => {
        if (creditsStatus === 'idle') {
            dispatch(fetchCredits());
        }
    }, [creditsStatus, dispatch]);

    if (creditsStatus === 'loading' || credits === null || creditsError) {
        return (
            <Chip
                label="Credits loading"
                size="small"
                sx={{ m: 2, display: 'inline-flex', position: 'fixed', right: '10px' }}
                // color={'info'}
                // variant="outlined"
                icon={<CloudIcon/>}

            />
        );
    } else if (creditsStatus === 'succeeded') {
        return (
            <Chip
                label={`Credits: ${credits}`}
                component="a"
                clickable
                href="/settings"
                color={
                    credits >= creditWarning
                        ? 'success'
                        : credits === 0
                            ? 'error'
                            : 'warning'
                }
                size="small"
                sx={{ m: 2, display: 'inline-flex', position: 'fixed', right: '10px' }}
                icon={credits >= creditWarning
                    ? <SentimentSatisfiedAltIcon/>
                    : credits === 0
                        ? <SentimentVeryDissatisfiedIcon/>
                        : <SentimentSatisfiedIcon/>}
            />
        );
    }

    return (
        <Chip
            label={`Credits: ${credits}`}
            component="a"
            clickable
            href="/settings"
            color={
                credits >= creditWarning
                    ? 'success'
                    : credits === 0
                        ? 'error'
                        : 'warning'
            }
            size="small"
            sx={{ m: 2, display: 'inline-flex', position: 'fixed', right: '10px' }}
        />
    );
}

export default CreditsComponent;
