import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  alertTitle: string;
  alertDialogue: string;
  onClose: () => void;
  onAgree: () => void;
}

const CustomAlert: React.FC<Props> = ({ open, alertTitle, alertDialogue, onClose, onAgree }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertDialogue}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/*<Button onClick={onClose}>Disagree</Button>*/}
        <Button onClick={onAgree} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomAlert;


// to call it example
// import * as React, { useState } from 'react';
// import CustomAlert from './CustomAlert'; // Adjust the import path accordingly
// import Button from '@mui/material/Button';
//
// const ParentComponent: React.FC = () => {
//   const [dialogOpen, setDialogOpen] = useState(false);
//
//   const handleOpenDialog = (folderKey: string) => {
//     setDialogOpen(true);
//   };
//
//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };
//
//   const handleAgree = async () => {
//     await function_you_want_to_happen(currentFolderKey);
//
//     handleCloseDialog();
//   };
//
//   <div>
//         <Button variant="outlined" onClick={() => handleOpenDialog('your-folder-key')}>
//           Appear Alert
//         </Button>
//         <CustomAlert
//           open={dialogOpen}
//           alertTitle="This is the alert title"
//           alertDialogue="alert description"
//           onClose={handleCloseDialog}
//           onAgree={handleAgree}
//         />
//       </div>