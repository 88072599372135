// hooks/useCredits.ts
import { useSelector, useDispatch } from 'react-redux';
import { fetchCredits, selectCredits, selectCreditsStatus } from '../redux/slice/credits';
import { RootState, AppDispatch } from '../redux/store';
import { useEffect } from 'react';

const useCredits = () => {
    const dispatch = useDispatch<AppDispatch>(), creditsStatus = useSelector(selectCreditsStatus),
        credits = useSelector(selectCredits);
    useEffect(() => {
        if (creditsStatus === 'idle') {
            dispatch(fetchCredits());
        }
    }, [creditsStatus, dispatch]);

    return credits;
};

export default useCredits;
